import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { remCalc } from '@/styles/typography/utils';
import { css } from 'styled-components';

export const inputBaseStyle = css`
  appearance: none;

  display: block;
  width: 100%;
  height: ${remCalc(40)};

  padding-top: var(--input-padding-y, ${remCalc(8)});
  padding-right: var(--input-padding-x, ${remCalc(12)});
  padding-bottom: var(--input-padding-y, ${remCalc(8)});
  padding-left: var(--input-padding-x, ${remCalc(12)});

  font-size: ${remCalc(16)};
  line-height: ${remCalc(16)};

  border: 2px solid var(--input-border-color, transparent);
  border-radius: var(--input-border-radius, ${remCalc(8)});
  outline: none;

  background-color: var(--input-background-color, ${COLORS.GRAY.HIGHLIGHT});
  color: ${COLORS.BLACK};

  transition: color 0.1s ease-in-out, border-color 0.1s ease-in-out, background-color 0.1s ease-in-out;

  &:not(:disabled):hover,
  &:not(:disabled):active {
    border-color: var(--input-border-color-hover, ${COLORS.BLACK});
  }

  &:focus {
    border-color: ${COLORS.BLUE.LOWLIGHT};
  }

  &::placeholder {
    color: ${hexToRGBA(COLORS.BLACK, 0.4)};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const inputErrorStyle = css`
  background-color: ${COLORS.RED.TINT};
  color: ${COLORS.RED.LOWLIGHT};
  padding-right: ${remCalc(28)};

  &:focus {
    border-color: ${COLORS.RED.LOWLIGHT};
  }

  &::placeholder {
    color: ${hexToRGBA(COLORS.RED.LOWLIGHT, 0.4)};
  }
`;
