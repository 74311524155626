'use client';
import DemoModalContent from '@/components/modals/DemoModal/DemoModalContent';
import { OPTIMIZELY_PAGES } from '@/constants/optimizely';
import optimizely from '@/lib/optimizely';
import useStore from '@/stores/useStore';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/navigation';
import { FC, useEffect } from 'react';

interface DemoModalProps {
  onClose?: () => void;
}

const DemoModal: FC<DemoModalProps> = () => {
  const { isDemoModalOpen, setDemoModalOpen } = useStore();

  const router = useRouter();

  useEffect(() => {
    const deactivate1 = optimizely.activatePage(OPTIMIZELY_PAGES.ANY_GET_STARTED_MODAL);
    const deactivate2 = optimizely.activatePage(OPTIMIZELY_PAGES.DEMO_MODAL);

    return () => {
      deactivate1();
      deactivate2();
    };
  }, []);

  return (
    <Transition
      show={isDemoModalOpen}
      enter="enter"
      enterFrom="enterFrom"
      enterTo="enterTo"
      leave="leave"
      leaveFrom="leaveFrom"
      leaveTo="leaveTo"
      appear
    >
      <DemoModalContent router={router} onClose={() => setDemoModalOpen(false)} />
    </Transition>
  );
};

export default DemoModal;
