import { API_APP_CONFIG } from '@/constants/routes';
import { GlobalPageProps } from '@/lib/craft';
import { createLogger } from '@/lib/debug';
import { EdgeConfig } from '@/types/vercel';
import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';

const logger = createLogger('global context');

export const GlobalContext = createContext<GlobalContext>({
  announcement: null,
  blog: null,
  categories: null,
  featureFlags: {},
  forceInvertedNav: false,
  getStartedModalFlow: null,
  lastUpdated: new Date(0).toString(),
  metadata: null,
  navigation: {
    blogNav: null,
    legalFooter: null,
    primaryFooter: null,
    primaryNav: null,
  },
  previousRouterPath: null,
  showGetStartedModal: false,
  showTrialModal: false,
  showDemoModal: false,
  showSubscribeModal: false,
  toggleGetStartedModal: () => {},
  toggleTrialModal: () => {},
  toggleDemoModal: () => {},
  toggleSubscribeModal: () => {},
  toggleInvertedNav: () => {},
});

export const useGlobalContext = (props: GlobalPageProps): GlobalContext => {
  const initialLoadRef = useRef<boolean>(true);
  const [globalProps, setGlobalProps] = useState<GlobalPageProps>(props);
  const router = useRouter();

  const [featureFlags, setFeatureFlags] = useState<GlobalContext['featureFlags']>({});
  const [showGetStartedModal, setShowGetStartedModal] = useState<boolean>(false);
  const [showTrialModal, setShowTrialModal] = useState<boolean>(false);
  const [showDemoModal, setShowDemoModal] = useState<boolean>(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState<boolean>(false);
  const [getStartedModalFlow, setGetStartedModalFlow] = useState<GlobalContext['getStartedModalFlow']>(null);
  const [previousRouterPath, setPreviousRouterPath] = useState<string | null>(null);
  const [forceInvertedNav, setForceInvertedNav] = useState<boolean>(false);

  const checkNewerProps = useCallback(
    (newProps: GlobalPageProps) => {
      if (newProps && globalProps && new Date(newProps.lastUpdated) > new Date(globalProps.lastUpdated)) {
        setGlobalProps(newProps);
      }
    },
    [globalProps],
  );

  useEffect(() => {
    // Check if there are new page props only on subsequent page views
    if (!initialLoadRef.current) {
      checkNewerProps(props);
    }
  }, [checkNewerProps, props]);

  useEffect(() => {
    const eventHandler = (url: string) => {
      /* Skip the initial page view */
      if (url !== router.asPath) {
        logger.info(`Setting previous router path to '${router.asPath}'`);
        setPreviousRouterPath(router.asPath);
      }
    };

    router.events.on('routeChangeStart', eventHandler);

    return () => router.events.off('routeChangeStart', eventHandler);
  }, [router.asPath]);

  useEffect(() => {
    fetch(API_APP_CONFIG).then(async (response) => {
      const config = await response.json();

      setFeatureFlags(config['feature-flags'] ?? {});
      checkNewerProps(config['global-page-props']);
    });
  }, []);

  useEffect(() => {
    initialLoadRef.current = false;
  }, []);

  useEffect(() => {
    try {
      window.__front = window.__front || {};
      window.__front.openTrialModal = () => void setShowTrialModal(true);
      window.__front.openDemoModal = () => void setShowDemoModal(true);
    } catch {}

    return () => {
      try {
        delete window.__front.openTrialModal;
        delete window.__front.openDemoModal;
      } catch {}
    };
  });

  const toggleGetStartedModal: GlobalContext['toggleGetStartedModal'] = (state = !showGetStartedModal, path = null) => {
    if (state) setGetStartedModalFlow(path);

    setShowGetStartedModal(state);
  };

  const toggleTrialModal: GlobalContext['toggleTrialModal'] = (state = !showTrialModal) => {
    setShowTrialModal(state);
  };

  const toggleDemoModal: GlobalContext['toggleDemoModal'] = (state = !showDemoModal) => {
    setShowDemoModal(state);
  };

  const toggleSubscribeModal: GlobalContext['toggleSubscribeModal'] = (state = !showSubscribeModal) =>
    setShowSubscribeModal(state);

  const toggleInvertedNav: GlobalContext['toggleInvertedNav'] = (state = !forceInvertedNav) =>
    setForceInvertedNav(state);

  return {
    ...globalProps,
    featureFlags,
    forceInvertedNav,
    getStartedModalFlow,
    previousRouterPath,
    showDemoModal,
    showGetStartedModal,
    showSubscribeModal,
    showTrialModal,
    toggleDemoModal,
    toggleGetStartedModal,
    toggleInvertedNav,
    toggleSubscribeModal,
    toggleTrialModal,
  };
};

export type GetStartedFlow = 'forceTrial' | 'forceDemo' | 'default';

export type GlobalContext = GlobalPageProps & {
  featureFlags: EdgeConfig['feature-flags'];
  forceInvertedNav: boolean;
  getStartedModalFlow: GetStartedFlow | null;
  previousRouterPath: string | null;
  showGetStartedModal: boolean;
  showTrialModal: boolean;
  showDemoModal: boolean;
  showSubscribeModal: boolean;
  toggleGetStartedModal: (shouldShow?: boolean, path?: GlobalContext['getStartedModalFlow']) => void;
  toggleTrialModal: (shouldShow?: boolean) => void;
  toggleDemoModal: (shouldShow?: boolean) => void;
  toggleInvertedNav: (inverted?: boolean) => void;
  toggleSubscribeModal: (shouldShow?: boolean) => void;
};
