import envClient from 'env.client';

export const CUSTOMER_STORIES_HOME = '/customer-stories';
export const FRONT_PAGE_HOME = '/blog';
export const FRONT_PAGE_SEARCH = '/blog/search';
export const HOMEPAGE = '/';
export const HUMAN_TOUCH = '/human-touch';
export const INTEGRATIONS_HOME = '/integrations';
export const INTEGRATIONS_PARTNER_INFO = '/partners/integration';
export const PRICING_PAGE = '/pricing';
export const PRIVACY_NOTICE = '/legal/privacy-notice';
export const PRODUCT_EXPLAINER = '/product-explainer';
export const PRODUCT_PAGE = '/product';
export const PRODUCT_TOUR_PAGE = '/product-tour';
export const REQUEST_DEMO = '/request-demo';
export const RESOURCES_HOME = '/resources';
export const SAAS_SERVICES_AGREEMENT = '/legal/saas-services-agreement';
export const WATCH_DEMO = '/watch-demo';

export const APP_DOMAIN = envClient.frontAppDomain ?? 'https://app.frontapp.com';
export const APP_SIGN_IN = `${APP_DOMAIN}/signin`;
export const CONTACT_US = 'https://help.frontapp.com/category/contact-us';
export const INTEGRATIONS_DOCUMENTATION = 'https://dev.frontapp.com';
export const HELP_CENTER = 'https://help.frontapp.com/';

export const GET_STARTED_MODAL_HASH = 'get-started';

/* API Routes */

export const API_APP_CONFIG = '/api/config/app';
export const API_CACHE_REVALIDATE = '/api/cache/revalidate';
export const API_CHILI_PIPER_HANDLER = '/api/chili-piper';
export const API_CHILI_PIPER_UPDATE_STATUS = '/api/chili-piper/update-status';
export const API_CLEARBIT_REVEAL = '/api/cb/reveal';
export const API_GEO_IP = '/api/geoip';
export const API_HUBSPOT_DOMAIN_CHECK = '/api/hs/domain-check';
export const API_HUBSPOT_IDENTIFY = '/api/hs/identify';

/* Temporarily Unavailable Paths */

export const TEMPORARILY_UNAVAILABLE_PATHS = [/^crm$/i, /^services$/i, /^use-case(\/.+)?$/i];

/* Paths not handled by Next */

export const EXTERNAL_ASSET_PATHS = /^(?:(?:static)?web)?assets\//i;
