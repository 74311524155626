import { ReactComponent as WarningIcon } from '@/assets/images/svg/glyphs/warning.svg';
import {
  InputFieldElementWrapper,
  InputFieldIconWrapper,
  InputFieldLabel,
  InputFieldMessage,
  InputFieldWrapper,
} from '@/components/forms/InputField';
import PhoneFieldElement from '@/components/forms/PhoneFieldElement';
import { COLORS } from '@/styles/color';
import { ChangeEventHandler, FC, FocusEventHandler, useId } from 'react';

interface PhoneFieldProps extends PropsWithClassName {
  name: string;
  label?: string;
  value?: string;
  invalid?: boolean;
  errorMessage?: string;
  translucent?: boolean;
  onNumberChange?: (phone: string) => void;
  onCountryChange?: (name: string, countryCode: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const PhoneField: FC<PhoneFieldProps> = (props) => {
  const id = useId();

  return (
    <InputFieldWrapper className={props.className}>
      {props.label && <InputFieldLabel htmlFor={id}>{props.label}</InputFieldLabel>}

      <InputFieldElementWrapper>
        <PhoneFieldElement
          name={props.name}
          value={props.value}
          invalid={props.invalid}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onNumberChange={props.onNumberChange}
          onCountryChange={props.onCountryChange}
          translucent={props.translucent}
        />

        {props.invalid && (
          <InputFieldIconWrapper>
            <WarningIcon title="Warning icon" />
          </InputFieldIconWrapper>
        )}
      </InputFieldElementWrapper>

      {props.invalid ? (
        <InputFieldMessage style={{ color: COLORS.RED.LOWLIGHT }}>{props.errorMessage}</InputFieldMessage>
      ) : null}
    </InputFieldWrapper>
  );
};

export default PhoneField;
