import LogoFarm from '@/components/LogoFarm';
import { GlobalsAwardsFragment, Maybe, ModuleLogoFarmFragment } from '@/graphql';
import { getSingle, notEmpty } from '@/lib/typescript-helpers';
import { FC } from 'react';

export interface LogoFarmModuleProps {
  props: CraftModuleFragment<ModuleLogoFarmFragment>;
  awards?: Maybe<GlobalsAwardsFragment>;
}

const LogoFarmModule: FC<LogoFarmModuleProps> = ({ props, awards }) => {
  if (Array.isArray(props)) {
    props = props[0];
  }

  if (!props) return null;

  return (
    <LogoFarm
      logosHeading={props.logosHeading ?? undefined}
      awardsHeading={props.awardsHeading ?? undefined}
      logos={
        props.logos
          .filter(notEmpty)
          ?.map((logo) => {
            const asset = getSingle(logo.monochromeCustomerLogo);

            if (!asset) return null;

            return {
              id: logo.id ?? '',
              customerName: logo.title ?? '',
              isTaller: logo?.isMonochromeLogoTaller ?? false,
              svgUrl: asset.url ?? '',
              height: asset.height ?? 0,
              width: asset.width ?? 0,
            };
          })
          .filter(notEmpty) ?? []
      }
      awards={
        props.showAwards
          ? awards?.awardBadges
              ?.map((award) => {
                const asset = getSingle(award?.badge);

                if (!asset) return null;

                return {
                  id: award?.id ?? '',
                  svgUrl: asset.url ?? '',
                  width: asset.width ?? 0,
                  height: asset.height ?? 0,
                  sizeMultiplier: award?.sizeMultiplier ?? 1,
                };
              })
              .filter(notEmpty) ?? []
          : []
      }
    />
  );
};

export default LogoFarmModule;
