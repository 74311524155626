"use client"

import { UserContext, useUserContext } from '@/context/user';

const UserContextProvider = ({children}) =>{
  const userContextValue = useUserContext();

  return (
  <UserContext.Provider value={userContextValue}>
    {children}
  </UserContext.Provider>
  )
} 

export default UserContextProvider;
