import { ANONYMOUS_ID_HEADER_NAME } from '@/constants/events';
import front from '@/lib/front';
import axios, { AxiosResponse } from 'axios';
import { getAppDomain } from './utils';

export default function signup(values: SignupRequest) {
  return axios.post<SignupRequest, AxiosResponse<SignupResponse>>(`${getAppDomain()}/api/1/signup`, values, {
    withCredentials: true,
    headers: {
      [ANONYMOUS_ID_HEADER_NAME]: front.getFaid(),
    },
  });
}

export interface SignupRequest {
  affiliate?: string;
  business_need?: string;
  code: string;
  company?: string;
  email: string;
  family_name?: string;
  given_name?: string;
  industry?: string;
  origin?: string;
  password: string;
  phone?: string;
  size?: string;
}

export interface SignupResponse {
  autojoin_company_slug?: string;
  company_slug?: string;
  first_admin_id?: string;
  first_login_url?: string;
  id?: string;
}

export interface SignupErrorResponse {
  code?: string;
}
