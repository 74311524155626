import checkEmailAvailability from './check-email';
import signup from './signup';
import validateEmail from './validate-email';

const frontBackend = {
  checkEmailAvailability,
  signup,
  validateEmail,
};

export default frontBackend;
