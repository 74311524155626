'use client'

import { ReactComponent as PrivacyChoicesGlyph } from '@/assets/images/svg/glyphs/privacy-choices.svg';
import Link from '@/components/_rebrand/Link';
import cx from 'classnames';
import { FC } from 'react';
import IconArrowFooterLink from './IconArrowFooterLink';
import IconGridBreakup from './IconGridBreakup';
import IconLogo from './IconLogo';


export interface FooterProps {
  backgroundColor?: 'violet';
  hideTopPattern?: boolean;
  tagline: string;

  badgeIcons: React.ReactNode[];

  socialLinks: {
    icon: React.ReactNode;
    title: string;
    href: string;
  }[];

  linkColumns: {
    sections: {
      title: string;
      links: {
        title: string;
        href: string;
        tag?: string;
      }[];
    }[];

    featuredLinks?: {
      title: string;
      href: string;
    }[];
  }[];

  smallLinksTitle: string;

  smallLinkColumns: {
    title: string;
    links: {
      title: string;
      href: string;
    }[];
  }[];

  copyright: string;

  legalLinks: {
    title: string;
    href: string;
  }[];
}

const Footer: FC<FooterProps> = ({
  backgroundColor = 'violet',
  hideTopPattern = false,
  tagline,
  badgeIcons = [],
  socialLinks = [],
  linkColumns = [],
  smallLinksTitle,
  smallLinkColumns = [],
  legalLinks = [],
  copyright,
}) => {
  const topSection = (
    <div
      className={cx('px-container-mobile md:px-container-desktop py-4 md:py-10', {
        'bg-violet-800 text-violet-200': backgroundColor === 'violet',
      })}
    >
      <div className="mx-auto max-w-grid">
        <div className="grid grid-cols-12 gap-y-8 gap-gutter-mobile md:gap-x-gutter-desktop">
          <div className="col-span-12 xl:col-span-4 flex flex-col gap-2">
            <IconLogo
              className={cx('w-[282px] h-[65px] block', {
                'text-violet-100': backgroundColor === 'violet',
              })}
            />
            <p className="text-30/36 max-w-44">{tagline}</p>

            <div className="flex gap-2">{badgeIcons}</div>

            <ul className="flex gap-2">
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    title={link.title}
                    href={link.href}
                    target="_blank"
                    className={cx(
                      'flex flex-col justify-center items-center w-6 h-6 rounded-full duration-200 transition-colors ease-in-quad',
                      {
                        'bg-violet-700 hover:bg-opacity-50': backgroundColor === 'violet',
                      },
                    )}
                  >
                    {link.icon}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-12 xl:col-span-8">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-gutter-mobile md:gap-x-gutter-desktop">
              {linkColumns.map((column, index) => (
                <div key={index} className="flex flex-col gap-2">
                  <div className="flex flex-col gap-8">
                    {column.sections.map((section, index) => (
                      <div key={index} className="flex flex-col gap-3">
                        <p className="text-10/14 uppercase text-white">{section.title}</p>
                        <ul className="flex flex-col gap-2">
                          {section.links.map((link, index) => (
                            <li key={index}>
                              <Link
                                href={link.href}
                                className={cx(
                                  'hover:text-white duration-200 transition-colors ease-in-quad flex gap-2',
                                  {
                                    'text-violet-200': backgroundColor === 'violet',
                                  },
                                )}
                              >
                                <span className="block">{link.title}</span>
                                {link.tag && (
                                  <span className="relative top-[1px] bg-green-200 text-green-800 block rounded-4 px-half text-10/15 h-[15px] uppercase font-mono">
                                    {link.tag}
                                  </span>
                                )}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}

                    {column.featuredLinks && (
                      <ul className="flex flex-col gap-2">
                        {column.featuredLinks.map((link, index) => (
                          <li key={index}>
                            <Link
                              href={link.href}
                              className={cx(
                                'hover:text-white duration-200 transition-colors ease-in-quad flex items-center gap-1',
                                {
                                  'text-violet-100': backgroundColor === 'violet',
                                },
                              )}
                            >
                              <span className="block">{link.title}</span>
                              <IconArrowFooterLink className="block" />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const smallLinksSection = (
    <div className="pb-5">
      <div className="grid grid-cols-12 gap-y-5 gap-gutter-mobile md:gap-x-gutter-desktop">
        <div className="col-span-12 xl:col-span-4">
          <p className="text-10/14 uppercase">{smallLinksTitle}</p>
        </div>
        <div className="col-span-12 xl:col-span-8">
          <div className="grid grid-cols-2 xl:grid-cols-4 gap-y-5 gap-gutter-mobile md:gap-x-gutter-desktop">
            {smallLinkColumns.map((column, index) => (
              <div key={index} className="flex flex-col gap-3">
                <p className="text-10/14 uppercase">{column.title}</p>
                <ul className="flex flex-col gap-2">
                  {column.links.map((link, index) => (
                    <li key={index}>
                      <Link
                        href={link.href}
                        className={cx('hover:text-white duration-200 transition-colors ease-in-quad', {
                          'text-violet-200': backgroundColor === 'violet',
                        })}
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const legalLinksSection = (
    <div
      className={cx('border-t border-solid pt-4 text-12/16', {
        'border-violet-500': backgroundColor === 'violet',
      })}
    >
      <div className="grid grid-cols-12 gap-y-4 gap-gutter-mobile md:gap-x-gutter-desktop">
        <div className="col-span-12 xl:col-span-4">
          <p
            className={cx({
              'text-violet-300': backgroundColor === 'violet',
            })}
          >
            {copyright}
          </p>
        </div>
        <div className="col-span-12 xl:col-span-8">
          <ul className="flex flex-col gap-2 xl:flex-row xl:gap-1 xl:justify-between">
            {legalLinks.map((link, index) => (
              <li key={index}>
                <Link
                  href={link.href}
                  className={cx('hover:text-white duration-200 transition-colors ease-in-quad', {
                    'text-violet-300': backgroundColor === 'violet',
                  })}
                >
                  {link.title}
                </Link>
              </li>
            ))}
            <li>
              <a
                onClick={() => {
                  window.OneTrust.ToggleInfoDisplay();
                }}
                className={cx('hover:text-white duration-200 transition-colors ease-in-quad', {
                  'text-violet-300': backgroundColor === 'violet',
                })}
              >Your Privacy Choices <PrivacyChoicesGlyph width={28} style={{ display: 'inline-block' }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const bottomSection = (
    <div
      className={cx('px-container-mobile md:px-container-desktop py-5', {
        'bg-violet-900 text-violet-200': backgroundColor === 'violet',
      })}
    >
      <div className="mx-auto max-w-grid">
        {smallLinksSection}
        {legalLinksSection}
      </div>
    </div>
  );

  return (
    <footer>
      {!hideTopPattern && (
        <div className="h-30 md:h-[21vw] relative w-full overflow-hidden">
          <IconGridBreakup className="absolute left-[-100vw] right-[-100vw] bottom-[-1px] w-[300vw] md:left-0 md:right-0 md:w-full h-auto text-violet-800" />
        </div>
      )}
      {topSection}
      {bottomSection}
    </footer>
  );
};

export default Footer;
