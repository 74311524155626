import LogoFarmModule from '@/modules/LogoFarm';
import Image from 'next/image';
import { FC } from 'react';
import Shared from "./index";
import logosData from './logos.json';
const SocialProofContent: FC = () => {
  const Icon = () => <Shared.IconWrapper><Image src="/webassets/rebrand/check-bullet.svg" width={24} height={24} alt="bullet point" /></Shared.IconWrapper>

  return (
    <Shared.SocialProofColumn >
      <h3>9,000 businesses trust Front to:</h3>
      <ul>
        <li>
          <Icon />
          Boost average CSAT to 87%</li>
        <li>
          <Icon />
          Improve productivity up to 46%</li>
        <li>
          <Icon />
          Reduce average response time by 25%</li>
      </ul>

      <div>
        {/* @ts-expect-error */}
        <LogoFarmModule props={logosData} />
      </div>
    </Shared.SocialProofColumn >
  )
}

export default SocialProofContent;
