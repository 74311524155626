"use client"

import { HubspotDomainCheckResponse } from '@/app/api/hs/domain-check/route';
import { ATTRIBUTION_COOKIE_KEYS, HUBSPOT_HUTK_COOKIE_NAME } from '@/constants/cookies';
import { GTM_EVENTS, GTM_VARIABLES } from '@/constants/gtm';
import { HubspotFieldNames, HubspotFormIDs } from '@/constants/hubspot';
import { OPTIMIZELY_EVENTS } from '@/constants/optimizely';
import { API_HUBSPOT_DOMAIN_CHECK } from '@/constants/routes';
import attribution from '@/lib/attribution';
import clearbit from '@/lib/clearbit';
import { getCookie } from '@/lib/cookies';
import { isGhostInspector } from '@/lib/ghost-inspector';
import logging from '@/lib/logging';
import optimizely from '@/lib/optimizely';
import axios, { AxiosError } from 'axios';
import { omitBy } from 'lodash';
import Script from 'next/script';
import { createLogger } from './debug';
import gtm from './gtm';

const PORTAL_ID = '4766782';

const logger = createLogger('hubspot');
const client = axios.create({
  baseURL: 'https://api.hsforms.com',
  timeout: 10000,
});

/**
 * Submit a form to Hubspot via POST
 */
const submitForm = async (formId: HubspotFormIDs, values: HubspotModel): Promise<HubspotResponse> => {
  const formLogger = logger.withTag(formId);

  formLogger.log('Form submission starting');

  if (!values[HubspotFieldNames.Email]) {
    throw new Error('Email must be set');
  }

  if (Array.isArray(values[HubspotFieldNames.Email])) {
    throw new Error('Email cannot be an array');
  }

  if (isGhostInspector(values[HubspotFieldNames.Email] as string)) {
    formLogger.warn('Ghost inspector email present; skipping form submission');

    return {};
  }

  try {
    if ((await checkHubspotEmailDomain(values[HubspotFieldNames.Email])).sendToHubspot === false) {
      formLogger.warn('Email domain blocked from submitting form');

      return {};
    }
  } catch (e) {
    logging.captureException(e);
    // Continue...
  }

  const submission: HubspotSubmission = {
    fields: transformHubspotModel(values),
    context: {
      hutk: getCookie(HUBSPOT_HUTK_COOKIE_NAME),
    },
  };

  /* Remove anything undefined/empty from context */
  submission.context = omitBy(
    submission.context,
    (value) => typeof value === 'undefined' || value === null || value === '',
  );

  /* Attribution */

  const attributionData = attribution.getAttributionFromCookie();

  submission.fields.push(
    {
      name: HubspotFieldNames.UTMCampaign,
      value: attributionData[ATTRIBUTION_COOKIE_KEYS.utmCampaign],
    },
    {
      name: HubspotFieldNames.UTMContent,
      value: attributionData[ATTRIBUTION_COOKIE_KEYS.utmContent],
    },
    {
      name: HubspotFieldNames.UTMMedium,
      value: attributionData[ATTRIBUTION_COOKIE_KEYS.utmMedium],
    },
    {
      name: HubspotFieldNames.UTMSource,
      value: attributionData[ATTRIBUTION_COOKIE_KEYS.utmSource],
    },
    {
      name: HubspotFieldNames.UTMTerm,
      value: attributionData[ATTRIBUTION_COOKIE_KEYS.utmTerm],
    },
    {
      name: HubspotFieldNames.Referrer,
      value: attributionData[ATTRIBUTION_COOKIE_KEYS.referrer],
    },
    {
      name: HubspotFieldNames.Gclid,
      value: attributionData[ATTRIBUTION_COOKIE_KEYS.gclid],
    },
    {
      name: HubspotFieldNames.FormPageURL,
      value: window.location.href ?? '',
    },
  );

  /* Add the Google Client ID */

  const googleClientId = await gtm.getClientId();

  submission.fields.push({
    name: HubspotFieldNames.GoogleClientID,
    value: googleClientId,
  });

  /* Optimizely */

  const { experimentIds, variationIds } = optimizely.getState();

  submission.fields.push(
    {
      name: HubspotFieldNames.OptimizelyExperiments,
      value: experimentIds.join(','),
    },
    {
      name: HubspotFieldNames.OptimizelyVariations,
      value: variationIds.join(','),
    },
  );

  /* Submit the form */

  try {
    const { data } = await client.post<HubspotResponse>(
      `/submissions/v3/integration/submit/${PORTAL_ID}/${formId}`,
      submission,
    );

    formLogger.success('Form submission accepted');

    gtm.event(GTM_EVENTS.HUBSPOT_FORM_SUCCESS, {
      [GTM_VARIABLES.HUBSPOT_FORM_ID]: formId,
      [GTM_VARIABLES.USER_EMAIL]: values[HubspotFieldNames.Email],
      [GTM_VARIABLES.USER_FIRST_NAME]: values[HubspotFieldNames.FirstName],
      [GTM_VARIABLES.USER_LAST_NAME]: values[HubspotFieldNames.LastName],
      [GTM_VARIABLES.USER_PHONE]: values[HubspotFieldNames.Phone],
    });

    optimizely.event(OPTIMIZELY_EVENTS.HUBSPOT_FORM_SUBMIT);

    clearbit.identify(values[HubspotFieldNames.Email]);

    return data;
  } catch (e) {
    const error: AxiosError<HubspotErrorResponse> = e;

    formLogger.error(`Form submission failed: ${error.message}`);

    /* Check for field errors */
    if (error.response?.data.errors) {
      const fieldErrors = transformHubspotErrors(error.response?.data.errors);

      throw new HubspotSubmissionError('Hubspot submission failed with errors', fieldErrors);
    }

    throw new HubspotSubmissionError('Hubspot submission failed');
  }
};

export const checkHubspotEmailDomain = async (email: string) => {
  try {
    const response = await fetch(`${API_HUBSPOT_DOMAIN_CHECK}?email=${encodeURIComponent(email)}`);

    return (await response.json()) as HubspotDomainCheckResponse;
  } catch (e) {
    return {
      sendToHubspot: true,
      getStartedAction: 'default',
      isPayingCustomer: false,
    } satisfies HubspotDomainCheckResponse;
  }
};

const setPagePath = (path: string) => {
  try {
    window._hsq.push(['setPath', path]);
  } catch (e) {
    //
  }
};

const trackPageView = (path: string) => {
  try {
    setPagePath(path);
    window._hsq.push(['trackPageView']);
  } catch (e) {
    //
  }
};

const setConsent = (optedIn: boolean = true) => {
  if (!optedIn) {
    window._hsp.push(['revokeCookieConsent']);
  }

  window._hsq.push(['doNotTrack', { track: optedIn }]);

  logger.log(`Consent opted ${optedIn ? 'in' : 'out'}`);
};

const hubspot = {
  submitForm,
  trackPageView,
  setPagePath,
  setConsent,
};

export default hubspot;

/**
 * Convert an object of field names and values to an array of objects to send to Hubspot
 */
export const transformHubspotModel = (model: HubspotModel): HubspotSubmissionField[] => {
  return (Object.keys(model) as HubspotFieldNames[])
    .flatMap((fieldName) => {
      const value = model[fieldName];

      if (Array.isArray(value)) {
        return value.map((fieldValue) => ({
          name: fieldName,
          value: convertValue(fieldValue),
        }));
      }

      return {
        name: fieldName,
        value: convertValue(value),
      };
    })
    .filter((field): field is HubspotSubmissionField => typeof field.value !== 'undefined');
};

function convertValue(value: any): string | number | undefined {
  if (value === undefined) return value;

  if (typeof value !== 'string' && typeof value !== 'number') {
    return String(value);
  }

  return value;
}

/**
 * Extract field errors from a Hubspot response into a readable array of field-level errors
 */
const transformHubspotErrors = (errors: HubspotErrorResponse['errors']): HubspotFieldError[] => {
  if (!errors || errors.length === 0) return [];

  return errors
    ?.map(({ message }) => {
      const matches = /fields\.(?<field>.+)'\. (?<error>.+)$/i.exec(message);

      if (matches?.groups) {
        return {
          field: matches.groups.field,
          error: matches.groups.error,
        };
      }

      return undefined;
    })
    .filter((error): error is HubspotFieldError => typeof error !== 'undefined' && !!error.field && !!error.error);
};

export const HubspotTrackingScript = (
  <Script
    src={`https://js.hs-scripts.com/${PORTAL_ID}.js`}
    id="hs-script-loader"
    data-hsjs-portal={PORTAL_ID}
    strategy="afterInteractive"
    type="text/javascript"
    onLoad={() => {
      logger.log('Hubspot tracking ready');
    }}
  />
);

export const HubspotInitScript = (
  // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
  <Script
    id="hubspot-init"
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
        window._hsp = window._hsp || [];
        window._hsq = window._hsq || [];
      `,
    }}
  />
);

/**
 * Exceptions
 */

export class HubspotSubmissionError extends Error {
  public fields: HubspotFieldError[] = [];

  constructor(message: string, fields?: HubspotFieldError[]) {
    super(message);

    if (fields) {
      this.fields = fields;
    }
  }
}

/**
 * Types & Interfaces
 */

export type HubspotModel<T = string | string[]> = {
  [key in HubspotFieldNames]?: T;
};

export type HubspotSubmissionField = {
  name: HubspotFieldNames;
  value: string;
};

export interface HubspotSubmission {
  fields: HubspotSubmissionField[];
  context: {
    hutk?: string;
    ipAddress?: string;
    pageName?: string;
    pageUri?: string;
  };
}

interface HubspotResponse {
  redirectUri?: string;
  inlineMessage?: string;
}

interface HubspotErrorResponse {
  status?: string;
  message?: string;
  correlationId?: string;
  errors?: {
    message: string;
    errorType: string;
  }[];
}

interface HubspotFieldError {
  field: HubspotFieldNames;
  error: string;
}

export interface HubspotContact {
  'vid': number;
  'canonical-vid': number;
  'merged-vids': any[];
  'portal-id': number;
  'is-contact': boolean;
  'properties': { [key: string]: Property };
  'form-submissions': any[];
  'list-memberships': ListMembership[];
  'identity-profiles': IdentityProfile[];
  'merge-audits': any[];
}

export interface IdentityProfile {
  'vid': number;
  'saved-at-timestamp': number;
  'deleted-changed-timestamp': number;
  'identities': Identity[];
}

export interface Identity {
  'type': string;
  'value': string;
  'timestamp': number;
  'is-primary'?: boolean;
}

export interface ListMembership {
  'static-list-id': number;
  'internal-list-id': number;
  'timestamp': number;
  'vid': number;
  'is-member': boolean;
}

export interface Property {
  value: string;
  versions: Version[];
}

export interface Version {
  'value': string;
  'source-type': SourceType;
  'source-id': null | string;
  'source-label': null | string;
  'updated-by-user-id': null;
  'timestamp': number;
  'selected': boolean;
}

export type SourceType =
  | 'BATCH_UPDATE'
  | 'INTEGRATION'
  | 'AUTOMATION_PLATFORM'
  | 'CALCULATED'
  | 'MERGE_CONTACTS'
  | 'ANALYTICS'
  | 'EMAIL'
  | 'AI_GROUP'
  | 'WAL_INCREMENTAL';
