'use client';

import { HubspotCompanySizeOptions, HubspotFieldNames, HubspotFormIDs } from '@/constants/hubspot';
import { GlobalContext } from '@/context';
import { OneTrustContext } from '@/context/onetrust';
import { createLogger } from '@/lib/debug';
import hubspot from '@/lib/hubspot';
import logging from '@/lib/logging';
import { validatePhone } from '@/lib/phone';
import { notEmpty } from '@/lib/typescript-helpers';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { fromPairs } from 'lodash';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useContext, useEffect, useState } from 'react';

const logger = createLogger('front chat');

const CHAT_ID = 'a2a3f9cc087311659704080d27c26961';

const FIELD_MAP = {
  'Name': HubspotFieldNames.FullName,
  'Email': HubspotFieldNames.Email,
  'Phone number': HubspotFieldNames.Phone,
} as const;

export const AppRouterFrontChatScript = () => {
  const pathname = usePathname() || '';
  const { showTrialModal, showDemoModal, showGetStartedModal } = useContext(GlobalContext);
  const { showBanner: showCookieBanner } = useContext(OneTrustContext);
  // const chatEnabled = useFeatureFlag<boolean>('front-chat');
  const chatEnabled = true;

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    if (!scriptLoaded) return;

    if (
      showDemoModal ||
      showTrialModal ||
      showGetStartedModal ||
      showCookieBanner ||
      pathname === '/legal' ||
      pathname.startsWith('/legal/') ||
      pathname === '/signup' ||
      pathname === '/jobs' ||
      pathname.startsWith('/partners/') ||
      pathname.startsWith('/llp/') ||
      pathname.startsWith('/lp/') ||
      pathname === '/press' ||
      pathname === '/download' ||
      pathname === '/game-changer-awards' ||
      pathname.startsWith('/game-changer-awards/')
    ) {
      setShowChat(false);
    } else {
      setShowChat(true);
    }
  }, [pathname, scriptLoaded, showCookieBanner, showDemoModal, showTrialModal, showGetStartedModal]);

  useEffect(() => {
    if (!showChat) return;

    let unbindOnContactFormSubmit: () => void;

    try {
      window.FrontChat('init', {
        chatId: CHAT_ID,
        useDefaultLauncher: true,
        onInitCompleted: () => {
          logger.log('Front Chat has initialized');

          const firstInitializedTime = new Date().getTime();

          unbindOnContactFormSubmit = window.FrontChat('onContactFormSubmit', (data: ContactFormSubmitResponse) => {
            /*
             * When this event handler is first bound, it immediately triggers. We do this simple check just to make
             * sure we skip the first trigger since it might be a repeat or completely empty.
             */

            const now = new Date().getTime();

            if (now - firstInitializedTime < 2500) {
              logger.warn('Skipping first onContactFormSubmit callback');

              return;
            }

            logger.log('onContactFormSubmit callback', data);

            const formFields = [...data.bot_contact_form_responses]
              .map((field) => {
                const hubspotField = FIELD_MAP[field.field_name];
                let value = field.response;

                if (!hubspotField) return null;

                if (hubspotField === HubspotFieldNames.Phone && validatePhone(value)) {
                  value = parsePhoneNumberFromString(value)!.format('E.164');
                }

                return [hubspotField, value];
              })
              .filter(notEmpty);

            const multipleChoiceOptions = data.decision_tree_responses
              .map((response) => {
                if (response.node_data.type !== 'multi_choice') return null;

                if (response.node_data.options.some((option) => option.value === '10 or less')) {
                  let companySize: string = '';

                  switch (response.response_data.response) {
                    // Old responses
                    case '10 or less':
                      companySize = HubspotCompanySizeOptions.XSmall;
                      break;
                    case '11 to 50':
                      companySize = HubspotCompanySizeOptions.Small;
                      break;
                    case '51 to 100':
                      companySize = HubspotCompanySizeOptions.Medium;
                      break;
                    case '101 to 1,000':
                      companySize = HubspotCompanySizeOptions.Large;
                      break;

                    // New responses
                    case '50 or less':
                      companySize = HubspotCompanySizeOptions.XSmall;
                      break;
                    case '51 to 150':
                      companySize = HubspotCompanySizeOptions.Medium;
                      break;
                    case '151 to 1,000':
                      companySize = HubspotCompanySizeOptions.XLarge;
                      break;
                    case '1,001+':
                      companySize = HubspotCompanySizeOptions.XXXLarge;
                      break;
                  }

                  return [HubspotFieldNames.CompanySize, companySize];
                }

                return null;
              })
              .filter(notEmpty);

            const responses = [...formFields, ...multipleChoiceOptions];

            if (!responses.filter(([key, value]) => !!value).length) {
              logger.warn('Skipping onContactFormSubmit callback: No responses');

              return;
            }

            hubspot
              .submitForm(HubspotFormIDs.FrontChatBot, fromPairs(responses))
              .then((data) => {
                logger.success('Submitted form successfully');
              })
              .catch((e) => {
                logger.error('Form submission failed', e);
              });
          });
        },
      });
    } catch (e) {
      logging.captureException(e);
    }

    return () => {
      if (unbindOnContactFormSubmit) {
        logger.log('Unbinding onContactFormSubmit handler');

        try {
          unbindOnContactFormSubmit();
        } catch (e) {
          logging.captureException(e);
        }
      }

      logger.log('Shutting down chat');

      try {
        window.FrontChat('shutdown');
      } catch (e) {
        logging.captureException(e);
      }
    };
  }, [showChat]);

  if (!chatEnabled) return null;

  return (
    <Script
      src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
      onReady={() => {
        logger.log('Front Chat script has loaded');
        setScriptLoaded(true);
      }}
    />
  );
};

interface ContactFormSubmitResponse {
  bot_contact_form_responses: {
    field_name: string;
    field_type: string;
    is_required: boolean;
    response: string;
  }[];
  decision_tree_responses: {
    node_data: {
      type: 'multi_choice' | 'message';
      message: string;
      options: {
        source_handle: string;
        value: string;
      }[];
    };
    node_id: string;
    response_data: {
      node_type: 'multi_choice' | 'message';
      response?: string;
      response_label?: string;
    };
    timestamp: number;
  }[];
}
