import { createLogger } from '@/lib/debug';
import envClient from 'env.client';
import Script from 'next/script';

const logger = createLogger('clearbit');

export const ClearbitScript = envClient.clearbitPublicKey ? (
  <Script
    src={`https://tag.clearbitscripts.com/v1/${envClient.clearbitPublicKey}/tags.js`}
    strategy="lazyOnload"
    referrerPolicy="strict-origin-when-cross-origin"
    onReady={() => {
      logger.log('script loaded');
    }}
  />
) : null;

const identify = (email: string) => {
  if (!envClient.clearbitPublicKey) {
    return void logger.warn('Failed to identify user; Clearbit public key is missing');
  }

  email = email.toLowerCase();

  try {
    window.clearbit.identify(email, {
      email,
    });

    logger.log(`identified user as ${email}`);
  } catch (e) {
    logger.error('could not identify user');
  }
};

const clearbit = {
  identify,
};

export default clearbit;

export interface ClearbitRevealResponse {
  ip: string;
  fuzzy: boolean;
  domain: string | null;
  type: 'company' | 'education' | 'government' | 'isp';
  company: Company | null;
  geoIP: GeoIp;
  confidenceScore: 'very_high' | 'high' | 'medium' | 'low' | null;
  role: string | null;
  seniority: string | null;
}

interface Company {
  id: string;
  name: string;
  legalName: string;
  domain: string;
  domainAliases: string[];
  site: Site;
  category: Category;
  tags: string[];
  description: string;
  foundedYear: number;
  location: string;
  timeZone: string;
  utcOffset: number;
  geo: Geo;
  logo: string;
  facebook: Facebook;
  linkedin: Linkedin;
  twitter: Twitter;
  crunchbase: Crunchbase;
  emailProvider: boolean;
  type: string;
  ticker: any;
  identifiers: Identifiers;
  phone: any;
  metrics: Metrics;
  indexedAt: string;
  tech: string[];
  techCategories: string[];
  parent: Parent;
  ultimateParent: UltimateParent;
}

interface Site {
  phoneNumbers: string[];
  emailAddresses: string[];
}

interface Category {
  sector: string;
  industryGroup: string;
  industry: string;
  subIndustry: string;
  gicsCode: string;
  sicCode: string;
  sic4Codes: string[];
  naicsCode: string;
  naics6Codes: string[];
  naics6Codes2022: string[];
}

interface Geo {
  streetNumber: string;
  streetName: string;
  subPremise: any;
  streetAddress: string;
  city: string;
  postalCode: string;
  state: string;
  stateCode: string;
  country: string;
  countryCode: string;
  lat: number;
  lng: number;
}

interface Facebook {
  handle: string;
  likes: number;
}

interface Linkedin {
  handle: string;
}

interface Twitter {
  handle: string;
  id: string;
  bio: string;
  followers: number;
  following: number;
  location: string;
  site: string;
  avatar: string;
}

interface Crunchbase {
  handle: string;
}

interface Identifiers {
  usEIN: any;
}

interface Metrics {
  alexaUsRank: number;
  alexaGlobalRank: number;
  trafficRank: string;
  employees: number;
  employeesRange: string;
  marketCap: any;
  raised: number;
  annualRevenue: any;
  estimatedAnnualRevenue: string;
  fiscalYearEnd: any;
}

interface Parent {
  domain: any;
}

interface UltimateParent {
  domain: any;
}

interface Metrics {
  alexaUsRank: number;
  alexaGlobalRank: number;
  employees: number;
  employeesRange: string;
  raised: number;
}

interface GeoIp {
  city: string;
  state: string;
  stateCode: string;
  country: string;
  countryCode: string;
}
