import { ReactComponent as Checkmark } from '@/assets/images/svg/glyphs/checkmark.svg';
import { COLORS } from '@/styles/color';
import { remCalc } from '@/styles/typography/utils';
import { forwardRef } from 'react';
import styled from 'styled-components';

const CheckboxLabel = styled.label`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${remCalc(16)};
  height: ${remCalc(16)};
`;

export const StyledCheckbox = styled.input`
  appearance: none;
  width: 100%;
  height: 100%;
  border: 2px solid ${COLORS.BLACK};
  border-radius: ${remCalc(4)};
  cursor: pointer;
  background-color: transparent;

  &:checked {
    border-color: transparent;
    background-color: ${COLORS.BLUE.LOWLIGHT};
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

const StyledCheckmark = styled(Checkmark)`
  position: absolute;
  width: 60%;
  height: auto;
  pointer-events: none;
`;

interface CheckboxProps extends PropsWithClassName {
  id?: string;
  checked: boolean;
  onToggle?: (checked: boolean) => void;
  preventPropagation?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return (
    <CheckboxLabel className={props.className}>
      {props.checked && <StyledCheckmark title="Checkmark" color={COLORS.WHITE} />}
      <StyledCheckbox
        id={props.id}
        ref={ref}
        type="checkbox"
        checked={props.checked}
        onClick={(e) => {
          if (props.preventPropagation) e.stopPropagation();
        }}
        onChange={(e) => {
          let newState = (e.target as HTMLInputElement).checked;

          if (newState === props.checked) return;

          /* Check if the checkbox is "controlled" */
          if (typeof props.checked === 'boolean') newState = !props.checked;

          if (props.onToggle) props.onToggle(newState);
        }}
      />
    </CheckboxLabel>
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  preventPropagation: true,
};

export default Checkbox;
