import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { validateEmail } from '@/lib/validation';
import frontBackend from '@/lib/api/front-backend';

interface EmailAvailabilityOptions {
  beforeCheck?: (email: string) => void | boolean;
  afterCheck?: (email: string) => void;
}

const useEmailAvailability = (
  options: EmailAvailabilityOptions = {},
): [EmailAvailability, Dispatch<SetStateAction<string>>] => {
  const [touched, setTouched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState<boolean>(false);

  useEffect(() => {
    let isCurrent = true;

    setIsAvailable(false);
    setHasFailed(false);

    if (!validateEmail(email)) {
      setIsChecking(false);
      return;
    }

    setIsChecking(true);
    setTouched(true);

    if (options.beforeCheck) {
      const result = options.beforeCheck(email);

      if (typeof result === 'boolean') {
        setIsAvailable(result);
        setIsChecking(false);

        return;
      }
    }

    const timeoutId = setTimeout(async () => {
      try {
        const available = await frontBackend.checkEmailAvailability(email);

        if (!isCurrent) return;

        setIsAvailable(available);
      } catch (e) {
        if (isCurrent) {
          setHasFailed(true);
        }
      }

      if (!isCurrent) return;

      setIsChecking(false);

      if (options.afterCheck) {
        setTimeout(() => {
          options.afterCheck!(email);
        }, 0);
      }
    }, 1500);

    return () => {
      isCurrent = false;
      clearTimeout(timeoutId);
    };
  }, [email]);

  let output: EmailAvailability = EmailAvailability.Untouched;

  if (!touched) {
    output = EmailAvailability.Untouched;
  } else if (isChecking) {
    output = EmailAvailability.Checking;
  } else if (hasFailed) {
    output = EmailAvailability.Error;
  } else if (isAvailable) {
    output = EmailAvailability.Available;
  } else if (!isAvailable) {
    output = EmailAvailability.Unavailable;
  }

  return [output, setEmail];
};

export default useEmailAvailability;

export enum EmailAvailability {
  Error = -2,
  Unavailable = -1,
  Untouched = 0,
  Available = 1,
  Checking = 2,
}
