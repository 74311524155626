import {
  AFFILIATE_COOKIE_KEY,
  AFFILIATE_COOKIE_NAME,
  ATTRIBUTION_COOKIE_KEYS,
  ATTRIBUTION_COOKIE_NAME,
} from '@/constants/cookies';
import { getCookie, setCookie } from './cookies';
import { createLogger } from './debug';

const FALLBACK_VALUE = '(not set)';

const logger = createLogger('attribution');

interface AttributionUTMs {
  [ATTRIBUTION_COOKIE_KEYS.utmCampaign]: string;
  [ATTRIBUTION_COOKIE_KEYS.utmContent]: string;
  [ATTRIBUTION_COOKIE_KEYS.utmMedium]: string;
  [ATTRIBUTION_COOKIE_KEYS.utmSource]: string;
  [ATTRIBUTION_COOKIE_KEYS.utmTerm]: string;
}

interface AttributionMisc {
  [ATTRIBUTION_COOKIE_KEYS.gclid]: string;
  [ATTRIBUTION_COOKIE_KEYS.referrer]: string;
}

type AttributionCookie = AttributionUTMs & AttributionMisc;

const parseQueryParams = () => {
  const urlParams = new URLSearchParams(window?.location?.search);
  const hasUtm = Array.from(urlParams.keys()).some((key) => key.startsWith('utm_'));
  const hasGclid = urlParams.has(ATTRIBUTION_COOKIE_KEYS.gclid);
  const hasAffiliate = urlParams.has(AFFILIATE_COOKIE_KEY);

  if (document.referrer) {
    logger.log(`Referrer present: ${document.referrer}`);
    updateReferrer();
  }

  if (hasUtm) {
    logger.log('UTMs present in the URL');
    setUtmsFromQuery();
  }

  if (hasGclid) {
    logger.log('GCLID present in the URL');
    setGclidFromQuery();
  }

  if (hasAffiliate) {
    logger.log('Affiliate present in the URL');
    setAffiliateFromQuery();
  }
};

const getAttributionFromCookie = (): AttributionCookie => {
  const cookieData = getCookie(ATTRIBUTION_COOKIE_NAME, true);

  return {
    [ATTRIBUTION_COOKIE_KEYS.utmCampaign]: '',
    [ATTRIBUTION_COOKIE_KEYS.utmContent]: '',
    [ATTRIBUTION_COOKIE_KEYS.utmMedium]: '',
    [ATTRIBUTION_COOKIE_KEYS.utmSource]: '',
    [ATTRIBUTION_COOKIE_KEYS.utmTerm]: '',
    [ATTRIBUTION_COOKIE_KEYS.gclid]: '',
    [ATTRIBUTION_COOKIE_KEYS.referrer]: '',
    ...(typeof cookieData === 'object' ? cookieData : {}),
  };
};

const getAffiliateFromCookie = (): string => getCookie(AFFILIATE_COOKIE_NAME) ?? '';

const setUtmsFromQuery = (): void => {
  const urlParams = new URLSearchParams(window?.location?.search);

  const filteredUtms = Array.from(urlParams.entries()).filter(([key, value]) => {
    return (
      key.toLowerCase().startsWith('utm_') &&
      (Object.values(ATTRIBUTION_COOKIE_KEYS) as string[]).includes(key.toLowerCase())
    );
  });

  const newUtms: AttributionUTMs = {
    [ATTRIBUTION_COOKIE_KEYS.utmCampaign]:
      filteredUtms.find(([key]) => key === ATTRIBUTION_COOKIE_KEYS.utmCampaign)?.[1] || FALLBACK_VALUE,
    [ATTRIBUTION_COOKIE_KEYS.utmContent]:
      filteredUtms.find(([key]) => key === ATTRIBUTION_COOKIE_KEYS.utmContent)?.[1] || FALLBACK_VALUE,
    [ATTRIBUTION_COOKIE_KEYS.utmMedium]:
      filteredUtms.find(([key]) => key === ATTRIBUTION_COOKIE_KEYS.utmMedium)?.[1] || FALLBACK_VALUE,
    [ATTRIBUTION_COOKIE_KEYS.utmSource]:
      filteredUtms.find(([key]) => key === ATTRIBUTION_COOKIE_KEYS.utmSource)?.[1] || FALLBACK_VALUE,
    [ATTRIBUTION_COOKIE_KEYS.utmTerm]:
      filteredUtms.find(([key]) => key === ATTRIBUTION_COOKIE_KEYS.utmTerm)?.[1] || FALLBACK_VALUE,
  };

  const cookieData = getAttributionFromCookie();

  logger.log('Updating cookie with new UTM data', newUtms);

  setCookie(
    ATTRIBUTION_COOKIE_NAME,
    {
      ...cookieData,
      ...newUtms,
    },
    { expires: 30 },
  );
};

const setGclidFromQuery = (): void => {
  const urlParams = new URLSearchParams(window?.location?.search);

  const gclid = urlParams.get(ATTRIBUTION_COOKIE_KEYS.gclid) || FALLBACK_VALUE;
  const cookieData = getAttributionFromCookie();

  if (!gclid) return;

  logger.log(`Updating cookie with new gclid value: ${gclid}`);

  setCookie(
    ATTRIBUTION_COOKIE_NAME,
    {
      ...cookieData,
      [ATTRIBUTION_COOKIE_KEYS.gclid]: gclid,
    },
    { expires: 30 },
  );

  if (gclid !== FALLBACK_VALUE) {
    /* Disabled until CORS issues are resolved */
    // front.sendEvent(FRONT_EVENTS.GCLID, gclid);
  }
};

const setAffiliateFromQuery = () => {
  const urlParams = new URLSearchParams(window?.location?.search);
  const affiliate = urlParams.get(AFFILIATE_COOKIE_KEY);

  if (!affiliate) return;

  logger.log(`Updating affiliate cookie with new value: ${affiliate}`);

  setCookie(AFFILIATE_COOKIE_NAME, affiliate);
};

const updateReferrer = () => {
  const parsedReferrer = new URL(document.referrer);

  if (/^(?:.+\.)?front(?:app)?\.com$/i.test(parsedReferrer.hostname)) {
    logger.log(`Skipping referrer update: internal domain`);

    return;
  }

  const cookieData = getAttributionFromCookie();

  if (cookieData.referrer === document.referrer) {
    logger.log(`Skipping referrer update: same as cookied value`);

    return;
  }

  logger.log(`Updating cookie with new referrer value: ${document.referrer}`);

  setCookie(
    ATTRIBUTION_COOKIE_NAME,
    {
      ...cookieData,
      [ATTRIBUTION_COOKIE_KEYS.referrer]: document.referrer,
    },
    { expires: 30 },
  );
};

const attribution = {
  parseQueryParams,
  getAttributionFromCookie,
  setUtmsFromQuery,
  setGclidFromQuery,
  getAffiliateFromCookie,
  setAffiliateFromQuery,
};

export default attribution;
