import Cookies from 'js-cookie';
import { createLogger } from './debug';

const logger = createLogger('cookies');

export const getCookieDomain = () => {
  let domain = window.location.hostname.substring(
    window.location.hostname.lastIndexOf('.', window.location.hostname.lastIndexOf('.') - 1) + 1,
  );

  // Add a leading period to set cookie on all subdomains
  if (domain.includes('.')) {
    domain = `.${domain}`;
  }

  return domain;
};

export const setCookie = (name: string, value: string | object, options?: Cookies.CookieAttributes) => {
  Cookies.set(name, value, {
    domain: getCookieDomain(),
    ...options,
  });

  logger.log(`Cookie "${name}" set`);
};

export const getCookie = (name: string, json: boolean = false) => {
  if (json) {
    return Cookies.getJSON(name);
  }

  return Cookies.get(name);
};
