import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import styled from 'styled-components';

const ChiliPiperTarget = styled.div`
  position: relative;
  min-height: 600px;
  margin: calc(var(--form-column-padding) * -1);
  margin-bottom: 0;

  .chilipiper-popup {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: ${COLORS.WHITE};
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .chilipiper-close {
      display: none;
    }

    .chilipiper-popup-window {
      max-width: none !important; // inline style override
      max-height: none !important; // inline style override
      width: 100% !important; // inline style override
      height: 100% !important; // inline style override
      border-radius: 0 !important; // inline style override
      background: transparent;

      .routing-loader {
        position: absolute;
        width: inherit;
        max-width: inherit;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -50px;

        .routing-loader-body {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin-bottom: 40px;
            font-weight: 500;
            font-size: 18px;
            color: #808284;
          }
        }
      }
    }

    .chilipiper-frame {
      overflow: hidden;
      border-radius: 0 !important;
      width: 100%;
      height: 100%;
      min-width: 100%;
    }

    .fabe-first-loading-label {
      margin-top: 30px;
      margin-left: 30px;
      display: block;
    }

    .chilipiper-popup-loading {
      width: 50px;
      height: 50px;
      background-image: url('https://js.chilipiper.com/images/loading.gif');
      background-size: 100%;
    }

    [data-chili-css='loader-dot'] {
      width: 1rem;
      height: 1rem;
      background: rgba(38, 52, 64, 0.32);

      &.dot1,
      &.dot2,
      &.dot3 {
        border-radius: 100%;
        display: inline-block;
        animation: bounce 1.4s infinite ease-in-out both;
      }

      &.dot1 {
        animation-delay: -0.32s;
      }

      &.dot2 {
        animation-delay: -0.16s;
      }
    }
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const Footer = styled.div`
  position: relative;
  margin: calc(var(--form-column-padding) * -1);
  margin-top: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.75rem;
  z-index: 1000;
  border-top: 1px solid ${hexToRGBA(COLORS.BLACK, 0.02)};
  box-shadow: 0px -15px 20px -20px ${hexToRGBA(COLORS.BLACK, 0.15)};
`;

export default {
  ChiliPiperTarget,
  Footer,
};
