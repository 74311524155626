import { GTM_VARIABLES } from '@/constants/gtm';
import { PrefillableHubspotFields } from '@/constants/hubspot';
import { API_CLEARBIT_REVEAL, API_HUBSPOT_IDENTIFY } from '@/constants/routes';
import { ClearbitRevealResponse } from '@/lib/clearbit';
import { createLogger } from '@/lib/debug';
import gtm from '@/lib/gtm';
import optimizely from '@/lib/optimizely';
import { poll } from '@/lib/utils';
import { cloneDeep } from 'lodash';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useLocalstorageState, useSessionstorageState } from 'rooks';

const logger = createLogger('user context');

export const UserContext = createContext<UserContext>({
  userData: {},
  setUserData: () => {},
  getTempUserData: () => ({}),
  setTempUserData: () => {},
});

export const useUserContext = (): UserContext => {
  const [clearbitData, setClearbitData] = useSessionstorageState<ClearbitRevealResponse | null>(
    'clearbit-reveal',
    null,
  );
  const [properties, setProperties] = useLocalstorageState<UserProperties>('user-data', {});
  const [tempProperties, setTempProperties] = useState<UserProperties>({});

  const setUserData = (newProps: Partial<UserProperties>) =>
    setProperties((prevProps) => ({ ...prevProps, ...newProps }));

  useEffect(() => {
    if (properties.enrichedFromHubspot) return;

    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('_hsenc')) return;

    logger.info('Attempting to enrich from Hubspot');

    poll(queryHubspot, {
      maxAttempts: 3,
      delay: (attempt) => attempt * 1000,
      initialDelay: 1500,
    })
      .then((data) => {
        if (!Object.keys(data).length) {
          return void logger.warn('No Hubspot enrichment data was returned');
        }

        logger.success('Enrichment data from Hubspot returned', data);

        setUserData({ ...data, enrichedFromHubspot: true });
      })
      .catch(() => {
        logger.error('Could not enrich from Hubspot');
      });
  }, []);

  useEffect(() => {
    if (!clearbitData) {
      logger.info('Attempting to enrich from Clearbit');

      return void queryClearbit()
        .then((data) => {
          logger.success('Enrichment data from Clearbit returned', data);

          setClearbitData(data);

          window.reveal = data;
        })
        .catch(() => {
          logger.error('Could not enrich from Clearbit');
        });
    }

    gtm.event('clearbit_reveal', {
      [GTM_VARIABLES.CLEARBIT_COMPANY_EMPLOYEE_RANGE]: clearbitData.company?.metrics.employeesRange,
      [GTM_VARIABLES.CLEARBIT_COMPANY_INDUSTRY]: clearbitData.company?.category.industry,
      [GTM_VARIABLES.CLEARBIT_COMPANY_NAME]: clearbitData.company?.name,
      [GTM_VARIABLES.CLEARBIT_COMPANY_REVENUE_RANGE]: clearbitData.company?.metrics.estimatedAnnualRevenue,
      [GTM_VARIABLES.CLEARBIT_COMPANY_SUBINDUSTRY]: clearbitData.company?.category.subIndustry,
    });

    optimizely.setUserAttributes({
      clearbit_company_employee_range: clearbitData.company?.metrics.employeesRange ?? null,
      clearbit_company_industry: clearbitData.company?.category.industry ?? null,
      clearbit_company_name: clearbitData.company?.name ?? null,
      clearbit_company_revenue_range: clearbitData.company?.metrics.estimatedAnnualRevenue ?? null,
      clearbit_company_subindustry: clearbitData.company?.category.subIndustry ?? null,
    });
  }, [clearbitData]);

  const getTempUserData = useCallback(() => {
    const data = cloneDeep(tempProperties);

    setTempProperties({});

    return data;
  }, [tempProperties]);

  const setTempUserData = (tempData: Partial<UserProperties>) => setTempProperties(tempData);

  return {
    userData: properties ?? {},
    setUserData,
    getTempUserData,
    setTempUserData,
  };
};

async function queryHubspot() {
  const response = await fetch(API_HUBSPOT_IDENTIFY);

  if (response.status === 404 || response.status === 204) {
    // No Hubspot enrichment data was returned
    return {};
  }

  if (!response.ok) {
    // Try again
    throw new Error();
  }

  return (await response.json()) as UserProperties;
}

async function queryClearbit() {
  const response = await fetch(API_CLEARBIT_REVEAL);

  if (!response.ok || response.status !== 200) {
    throw new Error('No Reveal data was returned');
  }

  return ((await response.json()) as { data: ClearbitRevealResponse }).data;
}

type UserProperties = Partial<Record<(typeof PrefillableHubspotFields)[number], any>> & Record<string, any>;

export type UserContext = {
  userData: UserProperties;
  setUserData: (data: Partial<UserProperties>) => void;
  getTempUserData: () => Partial<UserProperties>;
  setTempUserData: (data: Partial<UserProperties>) => void;
};
