import { HubspotFieldNames, HubspotFormIDs, PrefillableHubspotFields } from '@/constants/hubspot';
import { UserContext } from '@/context/user';
import gtm from '@/lib/gtm';
import hubspot, { HubspotModel, HubspotSubmissionError } from '@/lib/hubspot';
import logging from '@/lib/logging';
import { FormikConfig, FormikErrors, FormikHelpers, FormikValues, useFormik } from 'formik';
import { mapValues, omit } from 'lodash';
import { useContext, useEffect } from 'react';
import { createLogger } from './debug';

const logger = createLogger('formik');

export interface CustomFormikConfig<Values extends FormikValues> extends FormikConfig<Values> {
  fieldPrefillMapping?: Record<keyof Values, keyof Values | null>;
}

export interface FormikFormConfig<V = FormikValues> {
  formId: HubspotFormIDs | ((values: V) => HubspotFormIDs) | ((values: V) => Promise<HubspotFormIDs>);
  name: string;
  content: string;
  extraFields?: HubspotModel;
  beforeSubmit?: FormikConfig<V>['onSubmit'];
  onSuccess?: (values: V, actions: FormikHelpers<V>, formId: HubspotFormIDs) => void;
  onError?: (errors: FormikErrors<V>, values: V, actions: FormikHelpers<V>) => void;
  omitFields?: (keyof V)[];
  updateUserData?: boolean;
}

export const useCustomFormik = <Values extends FormikValues = FormikValues>(config: CustomFormikConfig<Values>) => {
  const { userData } = useContext(UserContext);

  config.validateOnBlur = config.validateOnBlur ?? false;

  if (config.initialValues) {
    const initialValues = mapValues(config.initialValues, (originalValue, fieldName) => {
      if (originalValue) return originalValue;

      let sourceFieldName = fieldName;

      if (config.fieldPrefillMapping) {
        const targetFieldName = config.fieldPrefillMapping[fieldName];

        if (targetFieldName === null) {
          return '';
        }

        if (typeof targetFieldName === 'string') {
          sourceFieldName = targetFieldName;
        }
      }

      if (sourceFieldName in userData) {
        const contextValue = userData[sourceFieldName];

        if (contextValue) {
          return contextValue;
        }
      }

      if (originalValue === '' || originalValue === null) return '';

      return originalValue;
    }) as Values;

    config.initialValues = initialValues;
  }

  const formik = useFormik<Values>(config);

  useEffect(() => {
    PrefillableHubspotFields.forEach((fieldName) => {
      let sourceFieldName = fieldName;

      if (config.fieldPrefillMapping) {
        const targetFieldName = config.fieldPrefillMapping[fieldName] as HubspotFieldNames | null;

        if (targetFieldName === null) {
          return '';
        }

        if (typeof targetFieldName === 'string') {
          sourceFieldName = targetFieldName;
        }
      }

      if (sourceFieldName in userData && sourceFieldName in config.initialValues) {
        try {
          const field = formik.getFieldMeta(sourceFieldName);

          if (!field.touched && !field.value) {
            formik.setFieldValue(sourceFieldName, userData[sourceFieldName]);
          }
        } catch (e) {
          //
        }
      }
    });
  }, [userData]);

  return formik;
};

export const useFormikHubspotSubmit = <V>({
  updateUserData = true,
  ...config
}: FormikFormConfig<V>): FormikConfig<V>['onSubmit'] => {
  const { setUserData } = useContext(UserContext);

  const sublogger = logger.withTag(config.name);

  return async (originalValues, actions) => {
    let formId: HubspotFormIDs;

    const values = { ...originalValues };

    if (typeof config.formId === 'function') {
      formId = await config.formId(values);
    } else {
      formId = config.formId;
    }

    sublogger.log('Submitting form...');

    if (config.beforeSubmit) {
      sublogger.log('Running beforeSubmit...');
      await config.beforeSubmit(values, actions);
      sublogger.success('beforeSubmit complete');
    }

    const formValues = {
      ...omit(values as Record<keyof V, string>, config.omitFields ?? []),
      ...(config.extraFields ?? {}),
      [HubspotFieldNames.ContentName]: config.content,
    };

    await hubspot
      .submitForm(formId, formValues)
      .then(async (response) => {
        sublogger.success('Form submission complete');

        gtm.track('submit_form', {
          form_name: config.name,
          form_content: config.content,
          form_id: formId,
        });

        if (config.onSuccess) {
          sublogger.log('Running onSuccess...');
          await config.onSuccess(values, actions, formId);
          sublogger.success('onSuccess complete');
        }

        // Store fields that should be saved in context

        if (updateUserData) {
          setUserData(
            (Object.keys(formValues) as HubspotFieldNames[])
              .filter((key) => PrefillableHubspotFields.includes(key))
              .reduce(
                (obj, fieldName) => ({ ...obj, [fieldName]: formValues[fieldName] }),
                {} as { [key in keyof typeof formValues]: string },
              ),
          );
        }
      })
      .catch(async (e) => {
        sublogger.error('Form submission failed');

        let fieldErrors = {};

        if (e instanceof HubspotSubmissionError) {
          fieldErrors = Object.fromEntries(e.fields.map((error) => [error.field, error.error]));
          sublogger.warn('Setting form errors...');
          actions.setErrors(fieldErrors);

          // This shouldn't happen, since our validation should prevent this
          logging.captureException(e, (err) => {
            err.addMetadata('form', {
              formId,
              formValues,
              fieldErrors,
            });
          });
        } else {
          logging.captureException(e);
          sublogger.error(e);
        }

        if (config.onError) {
          sublogger.log('Running onError...');
          await config.onError(fieldErrors, values, actions);
          sublogger.success('onError complete');
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
};
