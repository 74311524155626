import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const INPUT_SIZE = 40;
const INPUT_MARGIN = 8;

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;

  & + p {
    ${TEXT_SIZES[13]};
    color: ${COLORS.RED.BASE};
    margin-top: ${remCalc(6)};
  }
`;

const CodeInputCell = styled.div`
  width: ${remCalc(INPUT_SIZE)};
  height: ${remCalc(INPUT_SIZE)};

  display: block;
  padding: 8px 12px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid var(--input-border-color, transparent);
  border-radius: var(--input-border-radius, ${remCalc(8)});
  outline: none;

  background-color: var(--input-background-color, ${COLORS.GRAY.HIGHLIGHT});
  color: ${COLORS.BLACK};

  transition: color 0.1s ease-in-out, border-color 0.1s ease-in-out, background-color 0.1s ease-in-out;
  margin-right: ${remCalc(INPUT_MARGIN)};

  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }
`;

interface CodeInputWrapperProps {
  focused: boolean;
  selectedIndex: number;
  filled: boolean;
  disabled?: boolean;
  invalid?: boolean;
}

const CodeInputWrapper = styled.div<CodeInputWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 1 0px;
  width: 100%;

  ${(props) =>
    !props.filled &&
    props.focused &&
    css`
      ${CodeInputCell}:nth-child(${props.selectedIndex + 1}) {
        border-color: ${COLORS.BLACK};
      }
    `}

  ${(props) =>
    props.filled &&
    props.focused &&
    css`
      ${CodeInputCell} {
        border-color: ${COLORS.BLACK};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      ${CodeInputCell} {
        border-color: ${COLORS.GRAY.BASE};
        color: ${COLORS.GRAY.BASE};
      }
    `}

  ${(props) =>
    props.invalid &&
    !props.disabled &&
    css`
      ${CodeInputCell} {
        background-color: ${COLORS.RED.TINT};
        color: ${COLORS.RED.LOWLIGHT};

        &:focus {
          border-color: ${COLORS.RED.LOWLIGHT};
        }

        &::placeholder {
          color: ${hexToRGBA(COLORS.RED.LOWLIGHT, 0.4)};
        }
      }
    `}

  ${(props) =>
    props.invalid &&
    !props.disabled &&
    props.focused &&
    css`
      ${CodeInputCell}:nth-child(${props.selectedIndex + 1}) {
        border-color: ${COLORS.RED.LOWLIGHT};
      }
    `}
`;

const HiddenCodeInput = styled.input`
  position: absolute;
  border: none;
  font-size: ${remCalc(32)};
  text-align: center;
  background-color: transparent;
  outline: none;
  opacity: 0;
  width: 0;
  height: 0;
  appearance: none;
`;

interface CodeInputProps extends PropsWithClassName {
  length: number;
  code: string;
  onChange: (code: string) => void;
  onFocus?: (isInvalid?: boolean) => void;
  disabled?: boolean;
  invalid?: boolean;
}

const CodeInput: FC<CodeInputProps> = ({ code, length, onChange, onFocus, disabled, invalid, className }) => {
  const [validationCode, setValidationCode] = useState('');
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const onValidationCodeInputClick = () => ref.current?.focus();
  const onValidationCodeInputFocus = () => {
    if (onFocus) {
      onFocus(invalid);
    }

    setFocused(true);
  };
  const onValidationCodeInputBlur = () => setFocused(false);

  const onValidationCodeInputKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;

    if (e.key === 'Backspace') {
      setValidationCode(validationCode.slice(0, validationCode.length - 1));
    }
  };

  const onValidationCodeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const { value } = e.target as HTMLInputElement;
    const cleanValue = value.replace(/[^0-9]/gim, '');

    if (validationCode.length <= length) {
      setValidationCode((validationCode + cleanValue).slice(0, length));
    }
  };

  const selectedIndex = useMemo(() => {
    return validationCode.length < length ? validationCode.length : length - 1;
  }, [validationCode, length]);

  useEffect(() => {
    onChange(validationCode);
  }, [validationCode]);

  useEffect(() => {
    setValidationCode(code);
  }, [code]);

  return (
    <Wrapper className={className}>
      <CodeInputWrapper
        onClick={onValidationCodeInputClick}
        selectedIndex={selectedIndex}
        focused={focused}
        filled={validationCode.length === length}
        disabled={disabled}
        invalid={invalid}
      >
        {/* Create input cells */}
        {[...Array(length)].map((e, i) => (
          <CodeInputCell key={`cell-${i}`}>{validationCode.charAt(i)}</CodeInputCell>
        ))}

        <HiddenCodeInput
          ref={ref}
          id="hidden-code-input"
          value=""
          onFocus={onValidationCodeInputFocus}
          onBlur={onValidationCodeInputBlur}
          onChange={onValidationCodeInputChange}
          onKeyUp={onValidationCodeInputKeyUp}
        />
      </CodeInputWrapper>
    </Wrapper>
  );
};

export default CodeInput;
