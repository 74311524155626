'use client';

import Styled from '@/components/pages/signup/components';
import SignupModal from '@/components/pages/signup/Modal';
import { OPTIMIZELY_PAGES } from '@/constants/optimizely';
import optimizely from '@/lib/optimizely';
import useStore from '@/stores/useStore';
import { Transition } from '@headlessui/react';
import { FC, useEffect } from 'react';
interface SignupModalProps {
  onClose?: () => void;
}

const TrialModal: FC<SignupModalProps> = () => {
  const { isTrialModalOpen, setTrialModalOpen } = useStore();

  useEffect(() => {
    if (isTrialModalOpen) {
      const deactivate1 = optimizely.activatePage(OPTIMIZELY_PAGES.ANY_GET_STARTED_MODAL);
      const deactivate2 = optimizely.activatePage(OPTIMIZELY_PAGES.TRIAL_MODAL);

      return () => {
        deactivate1();
        deactivate2();
      };
    }
  }, [isTrialModalOpen]);

  return (
    <Transition
      show={isTrialModalOpen}
      as={Styled.TrialModalWrapper}
      enter="enter"
      enterFrom="enterFrom"
      enterTo="enterTo"
      leave="leave"
      leaveFrom="leaveFrom"
      leaveTo="leaveTo"
      appear
    >
      <Styled.FullscreenOverlay>
        <SignupModal
          onClose={() => {
            setTrialModalOpen(false);
          }}
        />
      </Styled.FullscreenOverlay>
    </Transition>
  );
};

export default TrialModal;
