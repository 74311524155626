import { APP_DOMAIN } from '@/constants/routes';
import { getCookie } from '@/lib/cookies';

export const getAppDomain = (): string => {
  /* Do not allow the cookie to override in production */
  if (window.location.hostname === 'front.com') {
    return APP_DOMAIN.replace(/\/$/i, '');
  }

  const overrideDomain = getCookie('front-preprod-app-domain') as string | undefined;

  return (overrideDomain ?? APP_DOMAIN).replace(/\/$/i, '');
};
