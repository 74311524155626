import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

export const validatePhone = (number: string, country?: string): boolean => {
  try {
    const countryCode = (country ?? '').toUpperCase();
    const result = parsePhoneNumber(number, countryCode as CountryCode);

    if (result) {
      return result.isValid();
    }

    return false;
  } catch (e) {
    /* In the case of something breaking, let forms submit */
    return true;
  }
};
