import { ANONYMOUS_ID_HEADER_NAME } from '@/constants/events';
import front from '@/lib/front';
import axios from 'axios';
import { getAppDomain } from './utils';

export default function validateEmail(values: ValidateEmailRequest) {
  return axios.post(`${getAppDomain()}/api/1/send_validation_email`, values, {
    withCredentials: true,
    headers: {
      [ANONYMOUS_ID_HEADER_NAME]: front.getFaid(),
    },
  });
}

export interface ValidateEmailRequest {
  email: string;
  password: string;
  phone?: string;
  business_need?: string;
  affiliate?: string;
  origin?: string;
}
