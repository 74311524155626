import { inputBaseStyle, inputErrorStyle } from '@/styles/components/shared-input-styles';
import { remCalc } from '@/styles/typography/utils';
import styled, { css } from 'styled-components';

export interface SelectFieldElementProps {
  invalid?: boolean;
  translucent?: boolean;
}

const SelectFieldElement = styled.select<SelectFieldElementProps>`
  ${inputBaseStyle};

  padding-right: ${remCalc(32)};

  ${(props) =>
    props.invalid &&
    css`
      ${inputErrorStyle};
      padding-right: ${remCalc(54)};
    `};

  ${(props) =>
    props.translucent &&
    css`
      mix-blend-mode: multiply;
    `};
`;

SelectFieldElement.defaultProps = {
  invalid: false,
};

export default SelectFieldElement;
