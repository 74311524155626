'use client';
import { OPTIMIZELY_SNIPPET_ID } from '@/constants/optimizely';
import { OneTrustScript } from '@/context/onetrust';
import { ChiliPiperInitScript } from '@/lib/chili-piper';
import { GoogleTagManagerInitScript } from '@/lib/gtm';
import { HubspotInitScript } from '@/lib/hubspot';
import { NavatticInitScript } from '@/lib/navattic';
import { onOptimizelyLoad } from '@/lib/optimizely';
import '@/styles/_rebrand/main.css';
import envServer from 'env.server';
import Script from 'next/script';

const SeoHead = () => {
  return (
    <head>
      {/* Meta */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta name="apple-mobile-web-app-title" content="Front" />
      <meta name="application-name" content="Front" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="preload" href="/staticwebassets/trial/background.png" as="image" />

      {OneTrustScript}
      {GoogleTagManagerInitScript}
      {HubspotInitScript}
      {ChiliPiperInitScript}
      {NavatticInitScript}

      <Script
        id="optimizely-action-applied-handler"
        dangerouslySetInnerHTML={{
          __html: `
                window.optimizely = window.optimizely || [];
                window.optimizelyActionAppliedCount = 0;
        
                window.optimizely.push({
                  type: 'addListener',
                  filter: {
                    type: 'action',
                    name: 'applied',
                  },
                  handler: (event) => {
                    window.optimizelyActionAppliedCount++;
                    document.documentElement.classList.remove('hide');
                  },
                });
              `,
        }}
      />

      {envServer.disableOptimizely ? null : (
        <Script
          id={`optimizely-snippet-${OPTIMIZELY_SNIPPET_ID}`}
          src={`https://cdn.optimizely.com/js/${OPTIMIZELY_SNIPPET_ID}.js`}
          onReady={onOptimizelyLoad}
        />
      )}

      <Script
        id="optimizely-changes-handler"
        dangerouslySetInnerHTML={{
          __html: `
                if (window.optimizelyWaitForChanges === true) {
                  document.documentElement.classList.add('hide');
                }
              `,
        }}
      />
    </head>
  );
};

export default SeoHead;
