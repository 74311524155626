import { ReactComponent as FrontLogoHorizontal } from '@/assets/images/svg/logos/front/front-logo-horizontal.svg';
import React, { FC, PropsWithChildren } from 'react';

interface StepProps extends PropsWithChildren {
  heading: string;
  subheading?: string;
}

const Step: FC<StepProps> = ({ heading, subheading, children }) => {
  return (
    <>
      <FrontLogoHorizontal title="Front logo" width={75} />
      <h2>{heading}</h2>
      {subheading ? <p>{subheading}</p> : null}
      {children}
    </>
  );
};

export default Step;
