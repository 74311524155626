import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { inputBaseStyle, inputErrorStyle } from '@/styles/components/shared-input-styles';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import { ChangeEventHandler, FC, FocusEventHandler, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled, { css } from 'styled-components';
import { InputFieldProps } from './InputFieldElement';

const DEFAULT_COUNTRY = 'us';
const SEARCH_PLACEHOLDER = 'Search for your country';
const PREFERRED_COUNTRIES = ['us', 'fr', 'uk', 'ca', 'in', 'au', 'de', 'nl', 'bra'];

interface PhoneFieldProps extends InputFieldProps {
  name: string;
  value?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onNumberChange?: (phone: string) => void;
  onCountryChange?: (name: string, countryCode: string) => void;
}

type PhoneInputWrapperProps = Pick<PhoneFieldProps, 'invalid' | 'translucent'>;

const PhoneInputWrapper = styled.div<PhoneInputWrapperProps>`
  width: 100%;

  /* Wrapper */
  .react-tel-input {
    /* Reset */
    font-family: inherit;
    font-size: inherit;
    /* End Reset */

    /* Input */
    .form-control {
      /* Reset */
      height: auto;
      letter-spacing: normal;
      margin: 0;
      /* End Reset */

      ${inputBaseStyle}

      ${(props) => props.invalid && inputErrorStyle};

      ${(props) =>
        props.translucent &&
        css`
          mix-blend-mode: multiply;
        `};

      padding-left: ${remCalc(38 + 8)};
    }

    /* Dropdown "Button" Wrapper */
    .flag-dropdown {
      background-color: transparent;
      border: none;
    }

    /* Dropdown "Button" */
    .selected-flag {
      width: ${remCalc(38)};

      &,
      &.open {
        border-radius: ${remCalc(8, 0, 0, 8)};
      }

      &:hover,
      &:focus,
      &:active,
      &.open {
        background-color: ${hexToRGBA(COLORS.BLACK, 0.05)};
      }
    }

    .country-list {
      border-radius: ${remCalc(8)};
      padding: ${remCalc(8)};
      box-shadow: rgb(0 0 0 / 8%) 0px 4px 16px;

      .search {
        padding: 0;
        background-color: transparent;
      }

      .search-box {
        ${inputBaseStyle};

        margin: 0;
        width: 100%;
        margin-bottom: ${remCalc(8)};
      }

      .country {
        ${TEXT_SIZES[14]};

        border-radius: ${remCalc(8)};

        &:hover {
          background-color: ${COLORS.GRAY.HIGHLIGHT};
        }

        &.highlight {
          font-weight: ${FONT_WEIGHTS.SEMIBOLD};
        }

        .country-name {
          color: ${COLORS.BLACK};
        }

        .dial-code {
          color: ${COLORS.GRAY.BASE};
        }
      }
    }
  }
`;

const PhoneFieldElement: FC<PhoneFieldProps> = (props) => {
  const [touched, setTouched] = useState(false);
  const [country, setCountry] = useState('');

  return (
    <PhoneInputWrapper invalid={props.invalid} translucent={props.translucent}>
      <PhoneInput
        inputProps={{ name: props.name }}
        country={DEFAULT_COUNTRY}
        value={props.value ?? ''}
        onChange={(phone, data: any, event) => {
          if (props.onNumberChange) {
            props.onNumberChange(phone);
          }

          if (props.onChange) {
            props.onChange(event);
          }

          if (data && data.countryCode !== country) {
            setCountry(data.countryCode);

            if (props.onCountryChange) {
              props.onCountryChange(data.name, data.countryCode);
            }
          }
        }}
        onBlur={(event) => {
          setTouched(true);

          if (props.onBlur) {
            props.onBlur(event);
          }
        }}
        isValid={true} /* We'll do our own validation */
        preferredCountries={PREFERRED_COUNTRIES}
        searchPlaceholder={SEARCH_PLACEHOLDER}
        preserveOrder={['preferredCountries']}
        specialLabel="" /* Disable built-in label */
        enableSearch
        disableSearchIcon
      />
    </PhoneInputWrapper>
  );
};

PhoneFieldElement.defaultProps = {
  invalid: false,
};

export default PhoneFieldElement;
