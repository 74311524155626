'use client';

import Heading from '@/components/_atoms/Heading';
import PrimaryInnerGlowButton from '@/components/buttons/primary';
// import Link from '@/components/Link';
import Modal from '@/components/modals/Modal';
import { OneTrustContext } from '@/context/onetrust';
import { parseHtml } from '@/lib/parse-html';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { ElementType } from 'domelementtype';
import { Element, Node } from 'domhandler';
import Link from 'next/link';
import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const GDPRConsentModal: FC = () => {
  const { showBanner } = useContext(OneTrustContext);

  const [localShowBanner, setLocalShowBanner] = useState<boolean>(showBanner);

  useEffect(() => {
    setLocalShowBanner(showBanner);
  }, [showBanner]);

  const { AlertNoticeText, AlertAllowCookiesText, AlertMoreInfoText, BannerRejectAllButtonText, ShowAlertNotice } =
    window.OneTrust.GetDomainData();

  if (!ShowAlertNotice || !localShowBanner || !showBanner) return null;

  const text = parseHtml(AlertNoticeText, [
    {
      shouldProcessNode: (node: Node) => {
        if (node.type === ElementType.Tag) {
          const el = node as Element;

          if (el.tagName === 'a') {
            return true;
          }
        }

        return false;
      },
      processNode: (node: Element, children) => {
        return (
          <Link href={node.attribs.href} target="_blank">
            {children}
          </Link>
        );
      },
    },
  ]);

  return (
    <StyledModal name="GDPR Cookie Consent Modal" preventClose>
      <Wrapper>
        <Heading level={3}>🍪 A note about our cookies</Heading>

        <p>{text}</p>

        <ButtonGroup>
          {/* Accept */}
          <PrimaryInnerGlowButton
            onClick={() => {
              window.OneTrust.AllowAll();
              setLocalShowBanner(false);
            }}
          >
            {AlertAllowCookiesText}
          </PrimaryInnerGlowButton>

          {/* Decline */}
          <PrimaryInnerGlowButton
            onClick={() => {
              window.OneTrust.RejectAll();
              setLocalShowBanner(false);
            }}
          >
            {BannerRejectAllButtonText}
          </PrimaryInnerGlowButton>

          {/* Settings */}
          <PreferencesButton
            onClick={() => {
              window.OneTrust.ToggleInfoDisplay();
            }}
          >
            {AlertMoreInfoText}
          </PreferencesButton>
        </ButtonGroup>
      </Wrapper>
    </StyledModal>
  );
};

export default GDPRConsentModal;

const StyledModal = styled(Modal)`
  --modal-max-width: ${remCalc(600)};
  --modal-backdrop-color: ${hexToRGBA(COLORS.BLACK, 0.3)};

  p {
    ${customTextSize(14, 20)};

    a {
      text-decoration: underline;
      font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${remCalc(30)};
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${remCalc(16)};

  ${mediaBreakpointUp('tablet')} {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
`;

const PreferencesButton = styled.button`
  ${customTextSize(14, 20)};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  text-decoration: underline;
  align-self: center;
  padding: ${remCalc(8)};
`;
