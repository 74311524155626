import { inputBaseStyle, inputErrorStyle } from '@/styles/components/shared-input-styles';
import styled, { css } from 'styled-components';

export interface InputFieldProps {
  invalid?: boolean;
  translucent?: boolean;
}

const InputFieldElement = styled.input<InputFieldProps>`
  ${inputBaseStyle};

  ${(props) => props.invalid && inputErrorStyle};

  ${(props) =>
    props.translucent &&
    css`
      mix-blend-mode: multiply;
    `};
`;

InputFieldElement.defaultProps = {
  invalid: false,
};

export default InputFieldElement;
