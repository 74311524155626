import HTMLToReact, { Parser as HTMLParser, TProcessingInstruction } from 'html-to-react';

const htmlToReactParser = new HTMLParser();
const processNodeDefinitions = new HTMLToReact.ProcessNodeDefinitions();

export const parseHtml = (html: string | null | undefined, instructions: TProcessingInstruction[] = []) =>
  htmlToReactParser.parseWithInstructions(html ?? '', () => true, [
    ...instructions,
    {
      shouldProcessNode: (node) => true,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]);

export const parseHeading = (html: string | null | undefined) =>
  parseHtml(html, [
    {
      shouldProcessNode: (node: any) => node.tagName === 'p',
      processNode: (node, children) => <span>{children}</span>,
    },
  ]);
