import NextImage, { ImageProps } from 'next/image';
import { FC } from 'react';

const Image: FC<ImageProps> = (imageProps) => {
  const props = { ...imageProps };

  /**
   * Running GIFs through Image Optimization sometimes fails, as it's not _technically_ a
   * valid format for Vercel Image Optimization. Vercel will not optimize GIFs anyway,
   * and simply "pass through" the original GIF, but sometimes it throws a 502 and won't
   * load. To combat that problem, we simply add the unoptimized flag if it's a GIF.
   * https://vercel.com/docs/concepts/image-optimization/limits-and-pricing#limits
   *
   */

  if (typeof props.src === 'string' && props.src.endsWith('.gif')) {
    props.unoptimized = true;
  }

  if (!props.alt) {
    props.alt = '';
  }

  return <NextImage {...props} />;
};

export default Image;

export type { ImageProps };
