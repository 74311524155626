import { ANONYMOUS_ID_HEADER_NAME } from '@/constants/events';
import axios from 'axios';
import { getAppDomain } from './api/front-backend/utils';
import { createLogger } from './debug';
import logging from './logging';

const logger = createLogger('front');

const generateGuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });

const getFaid = () => {
  try {
    return window.localStorage.getItem('front.aid');
  } catch (e) {
    return null;
  }
};

const setFaid = () => {
  let aid = getFaid();

  if (aid === null) {
    aid = generateGuid();
    logger.success(`Generated new \`aid\`: ${aid}`);

    try {
      window.localStorage.setItem('front.aid', aid);
    } catch (e) {
      logging.captureException(e);
    }
  }
};

const sendEvent = async (eventName: string, value?: any): Promise<void> => {
  const faid = getFaid();
  const stringifiedValue = JSON.stringify(value);

  try {
    await axios.post(
      `${getAppDomain()}/api/1/noauth/event`,
      {
        name: eventName,
        value: stringifiedValue,
        time: Date.now(),
      },
      {
        headers: {
          [ANONYMOUS_ID_HEADER_NAME]: faid,
        },
      },
    );

    logger.success(`Event "${eventName}" logged successfully`);
  } catch (e) {
    logger.fatal(`Failed to push event "${eventName}"`);
    logging.captureException(e);
  }
};

const front = {
  getFaid,
  setFaid,
  sendEvent,
};

export default front;
