import { COMMON_EMAIL_DOMAINS, SHARED_EMAIL_DOMAINS } from '@/constants/email';
import { validatePhone as libValidatePhone } from '@/lib/phone';

export const validateEmail = (email: string) => {
  return /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,10})+$/.test(email);
};

export const checkInvalidCharacters = (name: string) => {
  return /^[^!@#$%^*_=+{}\[\]\\<>;\\();:|`~]+$/gim.test(name);
};

export const validatePhone = (number: string) => {
  if (!number || number.length === 0 || number.replace(/\D/g, '').length === 0) {
    return false;
  }

  return libValidatePhone(number);
};

export const validatePassword = (password: string) => {
  return password.length >= 8 && /\d/.test(password) && /[A-Z]/.test(password);
};

export const extractEmailAddress = (email: string) => {
  const atIndex = email.lastIndexOf('@');

  if (atIndex === -1) return null;

  return email.substring(0, atIndex).toLowerCase();
};

export const extractEmailDomain = (email: string) => {
  const atIndex = email.lastIndexOf('@');

  if (atIndex === -1) return null;

  return email.substring(atIndex + 1, email.length).toLowerCase();
};

export const isPersonalEmail = (email: string) => {
  const domain = extractEmailDomain(email);

  if (!domain) return false;

  return COMMON_EMAIL_DOMAINS.includes(domain);
};

export const isSharedEmail = (email: string) => {
  const address = extractEmailAddress(email);

  if (!address) return false;

  return SHARED_EMAIL_DOMAINS.includes(address);
};

export const isWorkEmail = (email: string) => {
  return !isSharedEmail(email) && !isPersonalEmail(email);
};
