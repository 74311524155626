import { OPTIMIZELY_USER_ATTRIBUTES } from '@/constants/optimizely';
import { getCookieDomain } from '@/lib/cookies';
import envServer from 'env.server';
import Cookies from 'js-cookie';
import { createLogger } from './debug';

const logger = createLogger('optimizely');

const getState = (): OptimizelyState => {
  let campaignStates: { [key: string]: any } = {};

  const campaignIds: string[] = [];
  const experimentIds: string[] = [];
  const variationIds: string[] = [];

  if (!envServer.disableOptimizely) {
    try {
      campaignStates = window.optimizely.get('state').getCampaignStates();
    } catch (e) {
      if (typeof window.optimizely !== 'object') {
        logger.error('Could not export Optimizely campaign states: Optimizely is not loaded');
      } else {
        logger.error(`Could not export Optimizely campaign states: ${(e as Error).message}`);
      }

      return {
        campaignIds,
        experimentIds,
        variationIds,
      };
    }

    Object.keys(campaignStates).forEach((campaignId) => {
      const c = campaignStates[campaignId];

      if (c.isInCampaignHoldback !== true) {
        if (c.experiment?.id) {
          experimentIds.push(c.experiment.id);
        }

        if (c.variation?.id) {
          campaignIds.push(c.id);
          variationIds.push(c.variation.id);
        }
      }
    });
  }

  return {
    campaignIds,
    experimentIds,
    variationIds,
  };
};

const event = (eventName: string): void => {
  if (envServer.disableOptimizely) return;

  window.optimizely.push({
    type: 'event',
    eventName,
  });

  logger.log(`Event "${eventName}" triggered`);
};

const setUserAttribute = (attribute: OPTIMIZELY_USER_ATTRIBUTES, value: string | null): void => {
  if (envServer.disableOptimizely) return;

  window.optimizely.push({
    type: 'user',
    attributes: {
      [attribute]: value,
    },
  });

  logger.log(`User attribute "${attribute}" ${value ? `set to "${value}"` : 'unset'}`);

  activate();
};

const setUserAttributes = (attributes: Partial<Record<OPTIMIZELY_USER_ATTRIBUTES, string | null>>): void => {
  if (envServer.disableOptimizely) return;

  window.optimizely.push({
    type: 'user',
    attributes,
  });

  logger.log(`User attributes updated\n`, attributes);

  activate();
};

const activatePage = (pageName: string): (() => void) => {
  if (envServer.disableOptimizely) return () => {};

  logger.log(`Triggered activation of page "${pageName}"`);

  window.optimizely.push({
    type: 'page',
    pageName,
    isActive: true,
  });

  return () => deactivatePage(pageName);
};

const deactivatePage = (pageName: string): void => {
  if (envServer.disableOptimizely) return;

  logger.log(`Triggered deactivation of page "${pageName}"`);

  window.optimizely.push({
    type: 'page',
    pageName,
    isActive: false,
  });
};

const activate = (): void => {
  if (envServer.disableOptimizely) return;

  window.optimizely.push({
    type: 'activate',
  });

  logger.log(`Optimizely activated`);
};

const setConsent = (isOptIn: boolean = true): void => {
  if (envServer.disableOptimizely) return;

  if (isOptIn) {
    if (!!Cookies.get('optimizelyOptOut')) {
      logger.log(`Removing opt-out cookies`);
      // Attempt to remove opt-out cookies on the bare domain
      Cookies.remove('optimizelyOptOut');
      // Attempt to remove opt-out cookies on the root domain
      Cookies.remove('optimizelyOptOut', {
        domain: getCookieDomain(),
      });

      activate();
    }
  } else {
    window.optimizely.push({
      type: 'optOut',
      isOptOut: true,
    });
  }

  logger.log(`Consent opted ${isOptIn ? 'in' : 'out'}`);
};

export const onOptimizelyLoad = () => {
  window.optimizely.push({
    type: 'addListener',
    filter: {
      type: 'lifecycle',
      name: 'initialized',
    },
    handler: () => {
      logger.info('Optimizely initialized');
    },
  });

  window.optimizely.push({
    type: 'addListener',
    filter: {
      type: 'lifecycle',
      name: 'pageActivated',
    },
    handler: (event) => {
      logger.success(`Page '${event.data.page.name ?? event.data.page.apiName}' activated`);
    },
  });

  window.optimizely.push({
    type: 'addListener',
    filter: {
      type: 'lifecycle',
      name: 'pageDeactivated',
    },
    handler: (event) => {
      logger.info(`Page '${event.data.page.name ?? event.data.page.apiName}' deactivated`);
    },
  });

  window.optimizely.push({
    type: 'addListener',
    filter: {
      type: 'analytics',
      name: 'trackEvent',
    },
    handler: (event) => {
      logger.success(`Event '${event.data.name ?? event.data.apiName}' was tracked`);
    },
  });
};

const optimizely = {
  activate,
  event,
  setUserAttribute,
  setUserAttributes,
  activatePage,
  deactivatePage,
  getState,
  setConsent,
};

export default optimizely;

export interface Optimizely {
  push: (OptimizelyEvent) => void;
  get: (type: 'state') => {
    getCampaignStates: () => any;
  };
}

export interface OptimizelyState {
  campaignIds: string[];
  experimentIds: string[];
  variationIds: string[];
}
