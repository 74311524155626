'use client';
import { OneTrustCategories, OneTrustConsentWrapper, OneTrustContext, useOneTrustContext } from '@/context/onetrust';

export const PerfomanceConsentWrapper = ({ children }) => (
  <OneTrustConsentWrapper groupId={OneTrustCategories.Performance}>
    {children}
  </OneTrustConsentWrapper>
)


export const TargetingConsentWrapper = ({ children }) => (
  <OneTrustConsentWrapper groupId={OneTrustCategories.TargetingAndSocialMedia}>
    {children}
  </OneTrustConsentWrapper>
)


export const FunctionalConsentWrapper = ({ children }) => (
  <OneTrustConsentWrapper groupId={OneTrustCategories.Functional}>
    {children}
  </OneTrustConsentWrapper>
)


const OneTrustContextProvider = ({ children }) => {
  const oneTrustContext = useOneTrustContext();

  return (
    <OneTrustContext.Provider value={oneTrustContext}>
      {children}
    </OneTrustContext.Provider>
  )
}

export default OneTrustContextProvider;
