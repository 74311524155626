import { ReactComponent as CaretGlyph } from '@/assets/images/svg/glyphs/caret.svg';
import { ReactComponent as WarningIcon } from '@/assets/images/svg/glyphs/warning.svg';
import SelectFieldElement from '@/components/forms/SelectFieldElement';
import { COLORS } from '@/styles/color';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { remCalc } from '@/styles/typography/utils';
import { ChangeEvent, FocusEvent, forwardRef, useId } from 'react';
import styled from 'styled-components';

const StyledCaretGlyph = styled(CaretGlyph)`
  pointer-events: none;
`;

export const SelectFieldWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

export const SelectFieldElementWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  ${StyledCaretGlyph} {
    position: absolute;
    width: ${remCalc(20)};
    height: ${remCalc(20)};
    top: ${remCalc(10)};
    right: ${remCalc(8)};
    transform: rotate(180deg);
  }
`;

export const SelectFieldIconWrapper = styled.div`
  position: absolute;
  right: ${remCalc(32)};
  width: ${remCalc(16)};
  pointer-events: none;

  svg {
    color: ${COLORS.RED.LOWLIGHT};
  }
`;

export const SelectFieldLabel = styled.label`
  ${TEXT_SIZES[16]};

  display: inline-block;
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  padding-bottom: ${remCalc(8)};
  align-self: flex-start;
`;

export const SelectFieldError = styled.span`
  ${TEXT_SIZES[13]};

  color: ${COLORS.RED.LOWLIGHT};
  margin-top: ${remCalc(6)};
`;

export const SelectFieldHelpText = styled.span`
  ${TEXT_SIZES[13]};
  color: ${COLORS.GRAY.BASE};
  margin-bottom: ${remCalc(8)};
`;

export interface SelectFieldProps extends PropsWithClassName {
  name: string;
  label?: string;
  invalid?: boolean;
  errorMessage?: string;
  helpText?: string;
  value?: string;
  options: { [key: string]: string };
  translucent?: boolean;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onValueChange?: (value: string) => void;
}

const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const id = useId();

  return (
    <SelectFieldWrapper className={props.className}>
      {props.label && <SelectFieldLabel htmlFor={id}>{props.label}</SelectFieldLabel>}

      {!!props.helpText && <SelectFieldHelpText>{props.helpText}</SelectFieldHelpText>}

      <SelectFieldElementWrapper>
        <SelectFieldElement
          ref={ref}
          id={id}
          name={props.name}
          value={props.value}
          onChange={(e) => {
            if (props.onChange) props.onChange(e);
            if (props.onValueChange) props.onValueChange(e.target.value);
          }}
          onBlur={props.onBlur}
          invalid={props.invalid}
          translucent={props.translucent}
          disabled={props.disabled}
        >
          <option value="" selected={!!props.value} disabled>
            Select one
          </option>
          {Object.keys(props.options).map((key) => (
            <option key={key} value={props.options[key]} selected={props.value === props.options[key]}>
              {key}
            </option>
          ))}
        </SelectFieldElement>

        {props.invalid && (
          <SelectFieldIconWrapper>
            <WarningIcon title="Warning icon" />
          </SelectFieldIconWrapper>
        )}

        <StyledCaretGlyph />
      </SelectFieldElementWrapper>

      {props.invalid && props.errorMessage && <SelectFieldError>{props.errorMessage}</SelectFieldError>}
    </SelectFieldWrapper>
  );
});

SelectField.displayName = 'SelectField';

export default SelectField;
