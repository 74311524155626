import { ANONYMOUS_ID_HEADER_NAME } from '@/constants/events';
import front from '@/lib/front';
import { validateEmail } from '@/lib/validation';
import axios from 'axios';
import { getAppDomain } from './utils';

export default async function checkEmail(email: string) {
  if (!email) {
    throw new Error('You must provide an email address');
  }

  if (!validateEmail(email as string)) {
    throw new Error('Email address is not valid');
  }

  try {
    const {
      data: { status },
    } = await axios.get(`${getAppDomain()}/api/1/email_available/${encodeURIComponent(email)}`, {
      withCredentials: true,
      headers: {
        [ANONYMOUS_ID_HEADER_NAME]: front.getFaid(),
      },
    });

    return status === 'available';
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 409) {
        return false;
      }
    }

    throw e;
  }
}
