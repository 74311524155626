import { addReporter, createLogger } from '@/lib/debug';
import { isBrowser } from '@/lib/utils';
import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import envClient from 'env.client';
import envServer from 'env.server';
import isbot from 'isbot';

const logger = createLogger('logging');

const init = () => {
  if (!envClient.bugsnagApiKey) {
    throw new Error('Bugsnag application ID is missing');
  }

  const client = Bugsnag.start({
    apiKey: envClient.bugsnagApiKey,
    appType: isBrowser() ? 'browser' : 'server',
    appVersion: envClient.buildGitHashShort || 'unknown',
    enabledReleaseStages: ['production', 'preview'],
    logger: null,
    onError: (event) => {
      // Discard errors from bots
      if (isbot(event.device.userAgent)) {
        return false;
      }

      // Discard GTM errors
      if (
        event.errors[0].stacktrace.some((frame) => frame.file.toLowerCase().includes('googletagmanager.com/gtm.js'))
      ) {
        return false;
      }

      if (isBrowser()) {
        let hasAdBlock = false;

        const adBlockTestEl = document.getElementById('ad_box');

        if (adBlockTestEl) {
          hasAdBlock = window.getComputedStyle(adBlockTestEl).display === 'none';
        }

        event.addMetadata('device', {
          hasAdBlock,
        });
      }
    },
    onUncaughtException: isBrowser()
      ? undefined
      : (event) => {
          if (event.originalError instanceof Error && event.originalError.__bugsnagAlreadyHandled === true) {
            logger.debug('Discarding already-sent "uncaught exception" from Bugsnag');
            logger.error(event.originalError);
            return false;
          }
        },
    plugins: [new BugsnagPluginReact()],
    releaseStage: (envServer.vercelBuilding ? 'build' : envClient.vercelEnvironment ?? 'development').toLowerCase(),
  });

  if (isBrowser()) {
    addReporter({
      log: (logObj) => {
        let message = logObj.args
          .filter((arg) => typeof arg !== 'object')
          .map((arg) => (typeof arg !== 'string' ? JSON.stringify(arg) : arg))
          .join(', ');

        message = `[${logObj.tag}:${logObj.type}] ${message}`;

        const metadata = logObj.args
          .filter((arg) => typeof arg === 'object')
          .reduce(
            (meta, next, i) => ({
              ...meta,
              [`arg${i}`]: next,
            }),
            {},
          ) satisfies Record<string, any>;

        Bugsnag.leaveBreadcrumb(message, metadata, 'log');
      },
    });
  }

  return client;
};

const captureException = (error: Error, onErrorCallback?: (error: Event) => void) =>
  new Promise<Event>((resolve) => {
    error.__bugsnagAlreadyHandled = true;
    Bugsnag.notify(error, onErrorCallback, (_error, _event) => {
      logger.debug(`Bugsnag captured exception: ${error.message}`);
      resolve(_event);
    });
  });

const logging = {
  init,
  captureException,
};

export default logging;
