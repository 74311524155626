import { StyledCheckbox } from '@/components/forms/Checkbox';
import { MODAL_BKGD_COLOR } from '@/components/modals/Modal';
import Shared from '@/components/modals/shared';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { marginX, mediaBreakpointUp, paddingX, square } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled, { keyframes } from 'styled-components';

const TrialModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;

  &.enter,
  &.leave {
    transition: opacity 0.15s ease-in-out;
  }

  &.enterFrom,
  &.leaveTo {
    opacity: 0;
  }

  &.enterTo,
  &.leaveFrom {
    opacity: 1;
  }
`;

const FullscreenOverlay = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: calc(100vw * (900 / 1440) - 10px);
    bottom: 0;
    left: 16.65%;
    right: 3.45%;
    background: #f3f4f6;
  }

  ${mediaBreakpointUp('tablet')} {
    background-color: ${COLORS.WHITE};
    background-image: url('/staticwebassets/trial/background.png');
    background-size: 1024px;
    background-position: left top;
    background-repeat: no-repeat;
  }

  ${mediaBreakpointUp('desktop')} {
    background-size: 100%;
    background-position: center top;
  }
`;

const Modal = styled(Shared.Modal)`
  --modal-backdrop-color: ${hexToRGBA(MODAL_BKGD_COLOR, 0.25)};
`;

const LegalNotice = styled.div`
  ${customTextSize(12, 18)};
  color: ${Shared.MEDIUM_GRAY};
  margin: auto -24px -24px -24px;
  padding: 20px;
  text-align: center;

  p {
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: underline;
  }

  ${mediaBreakpointUp('tablet')} {
    ${marginX(-32)};
    padding-bottom: 0;
  }

  ${mediaBreakpointUp('desktop')} {
    ${marginX(-48)};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin-bottom: 8px;

  > :first-child {
    flex-shrink: 0;
  }

  label[for] {
    margin-left: ${remCalc(8)};
    cursor: pointer;
  }

  ${StyledCheckbox}:not(:checked) {
    border-color: ${Shared.MEDIUM_GRAY};
  }
`;

const QuoteWrapper = styled.div`
  position: relative;
  ${paddingX(64)};

  > svg {
    position: absolute;

    &:first-of-type {
      top: -42px;
      right: 32px;
    }

    &:last-of-type {
      bottom: -42px;
      left: 32px;
    }
  }
`;

const Quotation = styled.div`
  ${customTextSize(24, 32)}
  padding-left: ${remCalc(20)};
  position: relative;

  &::before {
    content: '“';
    position: absolute;
    top: ${remCalc(5.6)};
    left: 0;
    font-size: ${remCalc(40)};

    ${mediaBreakpointUp('tablet')} {
      top: ${remCalc(6.8)};
    }

    ${mediaBreakpointUp('desktop')} {
      top: ${remCalc(6)};
    }
  }

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(20, 26)}
  }

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(24, 32)}
  }
`;

const QuoteeWrapper = styled.div`
  display: flex;
  column-gap: ${remCalc(10)};
  padding-top: ${remCalc(20)};

  ${mediaBreakpointUp('tablet')} {
    column-gap: ${remCalc(20)};
  }
`;

const QuoteeName = styled.div`
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
`;

const QuoteeTitle = styled.div`
  ${customTextSize(14, 20)};
`;

const QuoteeAvatar = styled.div`
  img {
    border-radius: 50%;
  }
`;

const ErrorMessage = styled.p`
  border-top: 1px solid #e5e7eb;
  padding-top: 18px;
  color: ${COLORS.RED.LOWLIGHT};
`;

const ProgressBarKeyframes = keyframes`
  from {
    transform: translateX(-200px);
  }

  to {
    transform: translateX(200px);
  }
`;

const FullScreenLoader = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  /* Creates a new stacking context on browsers that don't yet support contain: strict. */
  isolation: isolate;
  /* Optimizes rendering, creates a new stacking context, and fixes a flickering issue in Safari 17.2+. */
  contain: strict;

  z-index: 999999;
  background-color: #fafbfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  svg {
    height: 150px;
    margin: min(20vh, 270px) auto;
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #000000;
    line-height: 1.2;
  }
`;

const ProgressBar = styled.div`
  width: 200px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #ffe8f0;
  /* Fixes rounded corner overflow in Safari. */
  will-change: transform;
  margin: 0px auto 40px;

  > div {
    height: 10px;
    width: 600px;
    margin: 0 -200px;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    background-color: #E2DCF6;
    background: radial-gradient(circle, #A857F1 0px, #E2DCF6 100px);

    animation-name: ${ProgressBarKeyframes};
    animation-duration: 4s;
  }
`;

const CodeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  line-height: 0;

  > p {
    ${customTextSize(13, 16)};
    color: ${Shared.MEDIUM_GRAY};
  }
`;

const NoticeBox = styled.div`
  position: relative;
  background: ${hexToRGBA(COLORS.BLACK, 0.95)};
  color: ${COLORS.WHITE};
  ${customTextSize(14, 22)};
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 10px;

  > div > * + * {
    margin-top: 15px;
  }

  a,
  button {
    color: ${COLORS.WHITE};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  svg {
    flex-shrink: 0;
  }

  ul {
    margin-top: 0;
    list-style: disc;
    padding-left: 1rem;
  }

  ${mediaBreakpointUp('tablet')} {
    position: absolute;
    top: 15px;
    left: calc(100% + 22px);
    width: 250px;
    z-index: 10;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);

    &::before {
      content: '';
      position: absolute;
      top: 16px;
      left: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid ${hexToRGBA(COLORS.BLACK, 0.95)};
    }
  }

  ${mediaBreakpointUp('desktop')} {
    width: 400px;
  }
`;

const NoticeBoxDismissButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
`;

const FormButtonWrapper = styled.div`
  text-align: right;
`;

const IconWrapper = styled.div`
  ${square(10)};
  display: inline-block;
  align-items: center;
  justify-content: center;
`;

const InitialFormTooltipWrapper = styled.fieldset`
  ${NoticeBox} {
    width: 100%;
    ${mediaBreakpointUp('tablet')} {
      width: 250px;
    }
  }
`;


export default {
  TrialModalWrapper,
  FullscreenOverlay,
  Modal,
  LegalNotice,
  CheckboxWrapper,
  QuoteWrapper,
  Quotation,
  QuoteeWrapper,
  QuoteeName,
  QuoteeTitle,
  QuoteeAvatar,
  ErrorMessage,
  FullScreenLoader,
  ProgressBar,
  CodeInputWrapper,
  NoticeBox,
  NoticeBoxDismissButton,
  FormButtonWrapper,
  IconWrapper,
  InitialFormTooltipWrapper
};
