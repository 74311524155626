'use client'
import gtm from '@/lib/gtm';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

const NavigationEvents = () => {
  const pathname = usePathname();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
    if (!initialLoad) {
      gtm.pageview(initialLoad, {
        new_route_url: pathname,
      });
    }
  }, [pathname, initialLoad])

  return <span />
}

export default NavigationEvents;
