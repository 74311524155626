import { mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize } from '@/styles/typography/utils';
import { FC } from 'react';
import styled from 'styled-components';

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Heading: FC<HeadingProps> = ({ level, ...props }) => {
  switch (level) {
    case 1:
      return <H1 {...props} />;
    case 2:
      return <H2 {...props} />;
    case 3:
      return <H3 {...props} />;
    case 4:
      return <H4 {...props} />;
    default:
      return null;
  }
};

export default Heading;

// Styled Components

const H1 = styled.h1`
  ${customTextSize(24, 30)}
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  letter-spacing: -0.01em;

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(32, 38)};
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${customTextSize(49, 58)};
  }
`;

const H2 = styled.h2`
  ${customTextSize(24, 30)};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  letter-spacing: -0.01em;

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(32, 38)};
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${customTextSize(49, 58)};
  }
`;

const H3 = styled.h3`
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${customTextSize(16, 21)};

  ${mediaBreakpointUp('desktop')} {
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    ${customTextSize(24, 34)};
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${customTextSize(32, 38)};
    letter-spacing: -0.01em;
  }
`;

const H4 = styled.h4`
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${customTextSize(16, 21)};

  ${mediaBreakpointUp('desktop')} {
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    ${customTextSize(24, 34)};
  }
`;
