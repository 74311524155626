import { ReactComponent as WhiteCloseIcon } from '@/assets/images/svg/glyphs/close-white.svg';
import InputField from '@/components/forms/InputField';
import PhoneField from '@/components/forms/PhoneField';
import SelectField from '@/components/forms/SelectField';
import Shared from '@/components/modals/shared';
import { InitialFormValues } from '@/components/pages/signup/InitialForm';
import { SignupModalStep } from '@/components/pages/signup/Modal';
import { GTM_VARIABLES } from '@/constants/gtm';
import {
  HubspotCompanySizeDropdownOptions,
  HubspotCompanySizeOptions,
  HubspotFieldLabels,
  HubspotFieldNames,
  HubspotFieldValidators,
  HubspotFormIDs,
  HubspotIndustryDropdownOptions,
} from '@/constants/hubspot';
import { OPTIMIZELY_EVENTS, OPTIMIZELY_PAGES } from '@/constants/optimizely';
import { TrialSignupPayload } from '@/constants/trial';
import { GlobalContext } from '@/context';
import { UserContext } from '@/context/user';
import frontBackend from '@/lib/api/front-backend';
import { SignupErrorResponse } from '@/lib/api/front-backend/signup';
import { getAppDomain } from '@/lib/api/front-backend/utils';
import attribution from '@/lib/attribution';
import { useCustomFormik } from '@/lib/formik';
import gtm from '@/lib/gtm';
import hubspot from '@/lib/hubspot';
import logging from '@/lib/logging';
import optimizely from '@/lib/optimizely';
import useStore from '@/stores/useStore';
import { AxiosError } from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import CodeInput from './CodeInput';
import Styled from './components';
import { ReactComponent as InfoIcon } from './InfoIcon.svg';

interface SecondaryFormProps extends SignupModalStep {
  initialFormValues: InitialFormValues;
  suggestDemo: boolean;
  onClose: () => void;
}

const SecondaryForm: FC<SecondaryFormProps> = ({ heading, subheading, initialFormValues, suggestDemo = true, onClose }) => {
  const { toggleTrialModal, toggleDemoModal } = useContext(GlobalContext);
  const { setDemoModalOpen } = useStore();
  const { setTempUserData } = useContext(UserContext);

  const [error, setError] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  const formik = useCustomFormik<TrialSignupPayload & { code: string }>({
    enableReinitialize: false,
    initialValues: {
      [HubspotFieldNames.CompanyName]: '',
      [HubspotFieldNames.CompanySize]: '',
      [HubspotFieldNames.FirstName]: '',
      [HubspotFieldNames.Industry]: '',
      [HubspotFieldNames.JobTitle]: '',
      [HubspotFieldNames.LastName]: '',
      [HubspotFieldNames.Phone]: '',
      code: '',
      ...initialFormValues,
    },
    onSubmit: (values, actions) => {
      setError('');

      const timeoutId = setTimeout(() => {
        setShowLoader(true);
      }, 2000);

      const affiliate = attribution.getAffiliateFromCookie() || '';

      frontBackend
        .signup({
          affiliate,
          code: values.code,
          company: values[HubspotFieldNames.CompanyName],
          email: values[HubspotFieldNames.Email],
          family_name: values[HubspotFieldNames.LastName],
          given_name: values[HubspotFieldNames.FirstName],
          industry: values[HubspotFieldNames.Industry],
          origin: window?.location?.href ?? '',
          password: values.password,
          size: values[HubspotFieldNames.CompanySize],
          phone: values[HubspotFieldNames.Phone],
        })
        .then(({ data }) => {
          // TODO re-add autojoin logic
          setShowLoader(true);

          gtm.trackTrialSignup({
            [GTM_VARIABLES.USER_EMAIL]: values[HubspotFieldNames.Email],
            [GTM_VARIABLES.USER_FIRST_NAME]: values[HubspotFieldNames.FirstName],
            [GTM_VARIABLES.USER_LAST_NAME]: values[HubspotFieldNames.LastName],
            affiliate,
            company: values[HubspotFieldNames.CompanyName],
            gclid: attribution.getAttributionFromCookie().gclid,
            industry: values[HubspotFieldNames.Industry],
            label: window.btoa(unescape(encodeURIComponent(data.first_admin_id ?? data.id ?? ''))),
            teammate_id: data.first_admin_id,
            marketing_email_opt_out: values[HubspotFieldNames.MarketingEmailOptOut],
          });

          optimizely.event(OPTIMIZELY_EVENTS.ANY_GET_STARTED_MODAL_FORM_SUBMIT);
          optimizely.event(OPTIMIZELY_EVENTS.TRIAL_SIGNUP);

          hubspot
            .submitForm(HubspotFormIDs.TrialSignup, {
              [HubspotFieldNames.Affiliate]: affiliate,
              [HubspotFieldNames.ContentName]: 'Trial Signup',
              [HubspotFieldNames.Email]: values[HubspotFieldNames.Email],
              [HubspotFieldNames.FirstName]: values[HubspotFieldNames.FirstName],
              [HubspotFieldNames.LastName]: values[HubspotFieldNames.LastName],
              [HubspotFieldNames.CompanyName]: values[HubspotFieldNames.CompanyName],
              [HubspotFieldNames.JobTitle]: values[HubspotFieldNames.JobTitle],
              [HubspotFieldNames.CompanySize]: values[HubspotFieldNames.CompanySize],
              [HubspotFieldNames.Industry]: values[HubspotFieldNames.Industry],
              [HubspotFieldNames.MarketingEmailOptOut]: String(values[HubspotFieldNames.MarketingEmailOptOut]),
              [HubspotFieldNames.Phone]: String(values[HubspotFieldNames.Phone]),
            })
            .catch((e) => {
              logging.captureException(e);
            })
            .finally(() => {
              setTimeout(() => {
                window.location.href = data.first_login_url ?? getAppDomain();
              }, 250);
            });
        })
        .catch((error: AxiosError<SignupErrorResponse>) => {
          clearTimeout(timeoutId);
          setShowLoader(false);

          logging.captureException(error);

          if (error.response?.data?.code === 'bad_validation_code') {
            actions.setFieldError('code', 'Incorrect validation code');
          } else if (error.response?.status === 429) {
            setError('Too many attempts, please try again later');
          } else {
            setError('An unexpected error occurred, please refresh and try again');
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
    validate: (values) => {
      const errors = {};

      errors[HubspotFieldNames.FirstName] = HubspotFieldValidators[HubspotFieldNames.FirstName](
        values[HubspotFieldNames.FirstName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.LastName] = HubspotFieldValidators[HubspotFieldNames.LastName](
        values[HubspotFieldNames.LastName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanyName] = HubspotFieldValidators[HubspotFieldNames.CompanyName](
        values[HubspotFieldNames.CompanyName],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Industry] = HubspotFieldValidators[HubspotFieldNames.Industry](
        values[HubspotFieldNames.Industry],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.CompanySize] = HubspotFieldValidators[HubspotFieldNames.CompanySize](
        values[HubspotFieldNames.CompanySize],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.JobTitle] = HubspotFieldValidators[HubspotFieldNames.JobTitle](
        values[HubspotFieldNames.JobTitle],
        {
          required: true,
        },
      );

      errors[HubspotFieldNames.Phone] = HubspotFieldValidators[HubspotFieldNames.Phone](
        values[HubspotFieldNames.Phone],
        {
          // Field is required if company size greater than 51
          required:
            parseInt(values[HubspotFieldNames.CompanySize], 10) >= parseInt(HubspotCompanySizeOptions.Medium, 10) ?
              true : false,
        },
      );

      errors['code'] = ((code) => {
        if (!code) {
          return 'Please enter the code sent to your email';
        }

        if (code.length !== 6) {
          return 'The code is 6 digits long';
        }
      })(values['code']);

      if (Object.values(errors).every((val) => val === undefined)) return;

      return errors;
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    if (!formik.values[HubspotFieldNames.Industry] || !formik.values[HubspotFieldNames.CompanySize]) {
      setShowNotice(false);
      return;
    }

    const companySize = parseInt(formik.values[HubspotFieldNames.CompanySize], 10);

    switch (formik.values[HubspotFieldNames.Industry]) {
      case HubspotIndustryDropdownOptions.Logistics:
      case HubspotIndustryDropdownOptions.Manufacturing:
      case HubspotIndustryDropdownOptions['Professional Services']:
      case HubspotIndustryDropdownOptions['Financial Services']:
      case HubspotIndustryDropdownOptions['Travel & Hospitality']:
      case HubspotIndustryDropdownOptions.Retail:
      case HubspotIndustryDropdownOptions['Real Estate']:
      case HubspotIndustryDropdownOptions['Consumer Services']:
        setShowNotice(companySize >= parseInt(HubspotCompanySizeOptions.Medium, 10));
        break;

      default:
        setShowNotice(companySize >= parseInt(HubspotCompanySizeOptions.Large, 10));
        break;
    }
  }, [formik.values[HubspotFieldNames.Industry], formik.values[HubspotFieldNames.CompanySize]]);

  useEffect(() => {
    const deactivate = optimizely.activatePage(OPTIMIZELY_PAGES.TRIAL_MODAL_STEP_TWO);

    return () => {
      deactivate();
    };
  }, []);

  return (
    <>
      <div>
        <h2>{heading}</h2>
        {subheading ? <p>{subheading}</p> : null}

        <form onSubmit={formik.handleSubmit} noValidate>
          <fieldset>
            <InputField
              type="text"
              name={HubspotFieldNames.FirstName}
              label={HubspotFieldLabels.FirstName}
              placeholder="Jamie"
              value={formik.values[HubspotFieldNames.FirstName]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched[HubspotFieldNames.FirstName] && !!formik.errors[HubspotFieldNames.FirstName]}
              errorMessage={formik.errors[HubspotFieldNames.FirstName] as string}
              translucent
            />

            <InputField
              type="text"
              name={HubspotFieldNames.LastName}
              label={HubspotFieldLabels.LastName}
              placeholder="Smith"
              value={formik.values[HubspotFieldNames.LastName]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched[HubspotFieldNames.LastName] && !!formik.errors[HubspotFieldNames.LastName]}
              errorMessage={formik.errors[HubspotFieldNames.LastName] as string}
              translucent
            />
          </fieldset>

          <fieldset>
            <InputField
              type="text"
              name={HubspotFieldNames.CompanyName}
              label={HubspotFieldLabels.CompanyName}
              placeholder="Your Company"
              value={formik.values[HubspotFieldNames.CompanyName]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched[HubspotFieldNames.CompanyName] && !!formik.errors[HubspotFieldNames.CompanyName]}
              errorMessage={formik.errors[HubspotFieldNames.CompanyName] as string}
              translucent
            />

            <InputField
              type="text"
              name={HubspotFieldNames.JobTitle}
              label={HubspotFieldLabels.JobTitle}
              placeholder="e.g., Director of Operations"
              value={formik.values[HubspotFieldNames.JobTitle]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched[HubspotFieldNames.JobTitle] && !!formik.errors[HubspotFieldNames.JobTitle]}
              errorMessage={formik.errors[HubspotFieldNames.JobTitle] as string}
              translucent
            />
          </fieldset>

          <fieldset>
            <SelectField
              name={HubspotFieldNames.CompanySize}
              label={HubspotFieldLabels.CompanySize}
              options={HubspotCompanySizeDropdownOptions}
              value={formik.values[HubspotFieldNames.CompanySize]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched[HubspotFieldNames.CompanySize] && !!formik.errors[HubspotFieldNames.CompanySize]}
              errorMessage={formik.errors[HubspotFieldNames.CompanySize] as string}
              translucent
            />

            <SelectField
              name={HubspotFieldNames.Industry}
              label={HubspotFieldLabels.Industry}
              options={HubspotIndustryDropdownOptions}
              value={formik.values[HubspotFieldNames.Industry]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched[HubspotFieldNames.Industry] && !!formik.errors[HubspotFieldNames.Industry]}
              errorMessage={formik.errors[HubspotFieldNames.Industry] as string}
              translucent
            />

            {suggestDemo && showNotice ? (
              <Styled.NoticeBox>
                <InfoIcon width={20} height={20} />
                <div>
                  <p>
                    We find that businesses of your size and industry would benefit from a personalized demo with our
                    Front experts. Would you like to get a demo instead?
                  </p>

                  <p>
                    <button
                      type="button"
                      onClick={() => {
                        setTempUserData(formik.values);
                        onClose();
                        setDemoModalOpen(true);
                      }}
                    >
                      Get a personalized demo →
                    </button>

                    <Styled.NoticeBoxDismissButton
                      type="button"
                      onClick={() => {
                        setShowNotice(false);
                      }}
                    >
                      <WhiteCloseIcon width={12} />
                    </Styled.NoticeBoxDismissButton>
                  </p>
                </div>
              </Styled.NoticeBox>
            ) : null}
          </fieldset>

          <PhoneField
            name={HubspotFieldNames.Phone}
            label={HubspotFieldLabels.Phone}
            value={formik.values[HubspotFieldNames.Phone]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched[HubspotFieldNames.Phone] && !!formik.errors[HubspotFieldNames.Phone]}
            errorMessage={formik.errors[HubspotFieldNames.Phone] as string}
            translucent
          />

          <Styled.CodeInputWrapper>
            <p>
              We sent a six-digit confirmation code to your email. It will expire in 30 minutes, enter it soon! Keep
              this window open while checking for your code.
            </p>

            <div>
              <CodeInput
                code={formik.values.code}
                length={6}
                onChange={(value) => formik.setFieldValue('code', value)}
                disabled={false}
                invalid={formik.touched.code && !!formik.errors.code}
                onFocus={(isInvalid) => {
                  if (formik.errors.code?.includes('Incorrect')) {
                    formik.setFieldValue('code', '');
                  }
                }}
              />

              {formik.touched.code && !!formik.errors.code ? <p>{formik.errors.code}</p> : null}
            </div>
          </Styled.CodeInputWrapper>

          {!!error ? <Styled.ErrorMessage>{error}</Styled.ErrorMessage> : null}

          <input type="submit" style={{ display: 'none' }} />

          <Shared.StyledPrimaryButton
            type="button"
            disabled={formik.isValidating || formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {formik.isSubmitting ? 'Starting your trial...' : 'Start my trial'}
          </Shared.StyledPrimaryButton>
        </form>
      </div>

      {showLoader ? (
        <Styled.FullScreenLoader>
          <svg width="175" height="175" viewBox="0 0 590 590" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H580.484V195.675H195.675V580.484H0V0ZM410.383 590C377.473 590 347.14 581.872 319.385 565.615C291.63 549.358 269.624 527.352 253.367 499.597C237.11 472.238 228.982 442.103 228.982 409.194C228.982 376.68 237.11 346.744 253.367 319.385C269.624 291.63 291.63 269.624 319.385 253.367C347.14 237.11 377.473 228.982 410.383 228.982C442.897 228.982 472.833 237.11 500.191 253.367C527.55 269.624 549.358 291.63 565.615 319.385C581.872 346.744 590 376.68 590 409.194C590 442.103 581.872 472.238 565.615 499.597C549.358 527.352 527.55 549.358 500.191 565.615C472.833 581.872 442.897 590 410.383 590Z" fill="url(#paint0_linear_3297_396)" />
            <defs>
              <linearGradient id="paint0_linear_3297_396" x1="295" y1="0" x2="295" y2="590" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A857F1" />
                <stop offset="1" stop-color="#8034BF" />
              </linearGradient>
            </defs>
          </svg>
          <Styled.ProgressBar>
            <div></div>
          </Styled.ProgressBar>

          <p>Welcome to Front, {formik.values[HubspotFieldNames.FirstName]}</p>
        </Styled.FullScreenLoader>
      ) : null}
    </>
  );
};

export default SecondaryForm;
