'use client';

import { createLogger } from '@/lib/debug';
import gtm from '@/lib/gtm';
import hubspot from '@/lib/hubspot';
import optimizely from '@/lib/optimizely';
import { OneTrustGeo } from '@/types/onetrust';
import envServer from 'env.server';
import Script from 'next/script';
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { createGlobalStyle } from 'styled-components';

const logger = createLogger('onetrust');

let DOMAIN_SCRIPT_ID = '4202c16d-ec7b-449b-9afb-fcd8f3819ab7';

// Use the "Test Scripts" when not in production
if (!envServer.isProduction) {
  DOMAIN_SCRIPT_ID += '-test';
}

export const OneTrustCategories = {
  Performance: 'C0002',
  Functional: 'C0003',
  TargetingAndSocialMedia: 'C0004',
  SocialMedia: 'C0005',
} as const;

interface OneTrustContextReturnType {
  geo?: OneTrustGeo;
  hasGroupConsent: (groupId: string) => boolean;
  isLoaded: boolean;
  showBanner: boolean;
  toggleInfoDisplay: () => void;
  isGDPR: boolean;
}

export const OneTrustContext = createContext<OneTrustContextReturnType>({
  geo: undefined,
  hasGroupConsent: (groupId) => false,
  isLoaded: false,
  showBanner: false,
  isGDPR: false,
  toggleInfoDisplay: () => { },
});

export const useOneTrustContext = (): OneTrustContextReturnType => {
  const activeGroups = useRef<string[]>([]);
  const [geo, setGeo] = useState<OneTrustGeo | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [isGDPR, setIsGDPR] = useState<boolean>(false);

  const onConsentChanged = useRef(() => {
    const sublogger = logger.withTag('onConsentChanged');

    if (!window.OnetrustActiveGroups) {
      sublogger.warn('Could not update consent in context, window.OnetrustActiveGroups was not set');

      return;
    }

    setIsGDPR(window.OneTrust.GetDomainData().ConsentModel.Name === 'opt-in');

    const isNoticeOnly = window.OneTrust.GetDomainData().ConsentModel.Name === 'notice only';
    const isBannerRequired =
      !isNoticeOnly && (window.OneTrust.GetDomainData().ShowAlertNotice || !window.OneTrust.GetDomainData().NoBanner);
    const isBannerDismissed = !isBannerRequired || window.OneTrust.IsAlertBoxClosedAndValid();

    setShowBanner(isBannerRequired && !isBannerDismissed);

    sublogger.log(`Consent updated: ${window.OnetrustActiveGroups}`);

    const newActiveGroups = window.OnetrustActiveGroups.split(',')
      .filter((str) => !!str)
      .map((str) => str.toUpperCase());

    const alwaysActiveGroups = window.OneTrust.GetDomainData()
      .Groups.filter((group) => group.Status === 'always active')
      .map((group) => group.OptanonGroupId);

    activeGroups.current = Array.from(new Set([...alwaysActiveGroups, ...newActiveGroups]));

    // Update consent for libs and third-party services

    // Set the consent as it stands right now _if_ they have consented
    if (isBannerDismissed) {
      sublogger.log(`Updating Google Consent`);

      const { GoogleConsent } = window.OneTrust.GetDomainData();

      // Update the users GTM consent based on their selections
      gtm.setConsent({
        ad_personalization: activeGroups.current.includes(GoogleConsent.GCAdPersonalization),
        // https://developer.onetrust.com/onetrust/docs/using-google-consent-mode#implementing-with-onetrust-cookie-compliance---no-gtm
        ad_storage: activeGroups.current.includes(OneTrustCategories.TargetingAndSocialMedia),
        ad_user_data: activeGroups.current.includes(GoogleConsent.GCAdUserData),
        // https://developer.onetrust.com/onetrust/docs/using-google-consent-mode#implementing-with-onetrust-cookie-compliance---no-gtm
        analytics_storage: activeGroups.current.includes(OneTrustCategories.Performance),
      });
    }

    optimizely.setConsent(activeGroups.current.includes(OneTrustCategories.Functional));

    hubspot.setConsent(activeGroups.current.includes(OneTrustCategories.Functional));
  });

  useEffect(() => {
    const onLoad = () => {
      logger.log('OneTrust onLoad triggered');

      const { GoogleConsent } = window.OneTrust.GetDomainData();

      setGeo(window.OneTrust.getGeolocationData());

      /*
       * Set the default consent in GTM based on the defaults set
       * in the geolocation rule that applies to the current user
       */
      gtm.setConsent(
        {
          ad_personalization: GoogleConsent.GCEnable ? getDefaultGroupStatus(GoogleConsent.GCAdPersonalization) : false,
          ad_storage: GoogleConsent.GCEnable ? getDefaultGroupStatus(GoogleConsent.GCAdStorage) : false,
          ad_user_data: GoogleConsent.GCEnable ? getDefaultGroupStatus(GoogleConsent.GCAdUserData) : false,
          analytics_storage: GoogleConsent.GCEnable ? getDefaultGroupStatus(GoogleConsent.GCAnalyticsStorage) : false,
        },
        true,
      );

      // Set the consent as it stands right now
      onConsentChanged.current();

      // Signal that everything is loaded and ready
      setIsLoaded(true);
    };

    try {
      if (window.OneTrust.GetDomainData().Domain) {
        // OneTrust is already loaded and ready, so run the callback right away
        onLoad();
      } else {
        throw new Error('OneTrust not ready');
      }
    } catch (e) {
      // OneTrust hasn't loaded yet, so set a callback to run once it's loaded and ready
      window.OptanonWrapper = onLoad;
    }

    const groupsUpdatedListener = () => {
      logger.log('Event triggered: OneTrustGroupsUpdated');
      onConsentChanged.current();
    };

    // https://my.onetrust.com/articles/en_US/Knowledge/UUID-bc6c634c-b234-acc7-21d3-fa2819a6e679
    window.addEventListener('OneTrustGroupsUpdated', groupsUpdatedListener);

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', groupsUpdatedListener);
    };
  }, []);

  const hasGroupConsent = useRef((groupId: string) => {
    return activeGroups.current.includes(groupId.toUpperCase());
  }).current;

  const toggleInfoDisplay = useRef(() => window.OneTrust.ToggleInfoDisplay()).current;

  return {
    geo,
    hasGroupConsent,
    isLoaded,
    showBanner,
    toggleInfoDisplay,
    isGDPR,
  };
};

const getDefaultGroupStatus = (id: string) => {
  // DNAC stands for "Do not assign category" in OneTrust
  if (id === 'DNAC' || id === '') return false;

  const match = window.OneTrust.GetDomainData().Groups.find((group) => group.OptanonGroupId === id);

  if (!match) {
    throw new Error(`Could not find group with an ID of "${id}"`);
  }

  return match.Status === 'active' || match.Status === 'always active';
};

export const OneTrustConsentWrapper = ({ groupId, children }: PropsWithChildren<{ groupId?: string }>) => {
  const { hasGroupConsent, isLoaded } = useContext(OneTrustContext);

  if (!isLoaded) return null;

  return !groupId || hasGroupConsent(groupId) ? children : null;
};

export const OneTrustScript = (
  // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
  <Script
    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    data-domain-script={DOMAIN_SCRIPT_ID}
    strategy="beforeInteractive"
  />
);

export const OneTrustHideBannerStyles = createGlobalStyle`
  /* We have our own banner, hide the original */
  #onetrust-banner-sdk { display: none; }
`;
