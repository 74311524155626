import { GeoWithCountryName } from '@/app/api/geoip/route';
import { HubspotChiliPiperFlowStatusOptions, HubspotFieldNames } from '@/constants/hubspot';
import { API_CHILI_PIPER_UPDATE_STATUS, API_GEO_IP } from '@/constants/routes';
import gtm from '@/lib/gtm';
import { isPersonalEmail } from '@/lib/validation';
import Script from 'next/script';

export const ChiliPiperInitScript = (
  // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
  <Script
    id="chili-piper-init"
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
        function q(a){return function(){ChiliPiper[a].q=(ChiliPiper[a].q||[]).concat([arguments])}}window.ChiliPiper=window.ChiliPiper||"submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function(a,b){a[b]=q(b);return a},{});
      `,
    }}
  />
);

export const ChiliPiperScript = (
  <>
    {/* I scoped out the marketing.js and found that if an element with an ID of "chilipiper-css" is
        present, they will not load their own custom CSS. */}
    <div id="chilipiper-css" style={{ display: 'none' }}></div>
    <Script src="https://js.chilipiper.com/marketing.js" type="text/javascript" strategy="lazyOnload" />
  </>
);

type ChiliPiperValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name: string;
  industry: string;
  company_size: string;
};

type ChiliPiperEvent =
  | 'availability-loaded'
  | 'booked'
  | 'booking-confirmed'
  | 'onClose'
  | 'onDestroy'
  | 'onError'
  | 'onRouted'
  | 'onSuccess'
  | 'onTimeout'
  | 'phone-selected'
  | 'phoneClose'
  | 'timeslot-selected'
  | 'video-selected';

type ChiliPiperEventHandler = (eventName: ChiliPiperEvent, args: any) => void;

export const ChiliPiperTargetID = 'chili-piper-target';

export class ChiliPiperSession {
  protected user: ChiliPiperValues;

  protected isPayingCustomer: boolean = false;

  protected eventHandler?: ChiliPiperEventHandler;

  protected timeoutId: NodeJS.Timeout | null = null;

  protected timeoutMessageId: string | null = null;

  protected shouldSendExitEvent: boolean = false;

  constructor(values: ChiliPiperValues, eventHandler?: ChiliPiperEventHandler) {
    this.user = values;

    this.eventHandler = eventHandler;

    window.addEventListener('message', this.chiliPiperEventListener);
  }

  public loadFrame = async (forceSchedule: boolean = false) => {
    let ipCountry: string = '';

    const geoIpResponse = await fetch(API_GEO_IP);

    if (geoIpResponse.ok) {
      const { countryName } = (await geoIpResponse.json()) as GeoWithCountryName;

      if (countryName) {
        ipCountry = countryName;
      }
    }

    window.ChiliPiper.submit(
      'frontapp',
      forceSchedule ? 'inbound-demo-request-scheduling-only' : 'inbound-demo-request',
      {
        lead: {
          [HubspotFieldNames.FirstName]: this.user.first_name,
          [HubspotFieldNames.LastName]: this.user.last_name,
          [HubspotFieldNames.Email]: this.user.email,
          [HubspotFieldNames.Phone]: this.user.phone,
          [HubspotFieldNames.CompanyName]: this.user.company_name,
          industry_self_select__c: this.user.industry,
          team_size__c: this.user.company_size,
          free_email_domain__c: String(isPersonalEmail(this.user.email)),
          ip_country__c: ipCountry,
        },
        domElement: `#${ChiliPiperTargetID}`,
        onError: () => {
          if (this.eventHandler) {
            this.eventHandler('onError', {});
          }
        },
        onClose: () => {
          if (this.eventHandler) {
            this.eventHandler('onClose', {});
          }
        },
        onSuccess: () => {
          if (this.eventHandler) {
            this.eventHandler('onSuccess', {});
          }
        },
        onRouted: () => {
          if (this.eventHandler) {
            this.eventHandler('onRouted', {});
          }
        },
      },
    );

    this.startTimeout();

    this.shouldSendExitEvent = true;
  };

  protected chiliPiperEventListener = (event: MessageEvent) => {
    if (event.origin !== 'https://frontapp.chilipiper.com') return;

    // eslint-disable-next-line no-console
    console.log(event.data);

    switch (event.data.action as ChiliPiperEvent) {
      case 'availability-loaded':
        this.shouldSendExitEvent = true;
        this.startTimeout();
        break;

      case 'phone-selected':
        this.shouldSendExitEvent = false;
        this.updateStatus(HubspotChiliPiperFlowStatusOptions.RequestedCall);

        break;

      case 'video-selected':
        this.shouldSendExitEvent = false;
        break;

      case 'booked':
        this.shouldSendExitEvent = false;
        this.updateStatus(HubspotChiliPiperFlowStatusOptions.Booked);

        gtm.track('scheduled_meeting', {
          location: 'Chili Piper Widget',
        });

        break;

      case 'booking-confirmed':
        this.shouldSendExitEvent = false;
        break;
    }

    if (this.eventHandler) {
      this.eventHandler(event.data.action, event.data.args);
    }
  };

  public destroy = () => {
    window.removeEventListener('message', this.chiliPiperEventListener);

    if (this.eventHandler) {
      this.eventHandler('onDestroy', {});
    }

    if (this.shouldSendExitEvent) {
      this.updateStatus(HubspotChiliPiperFlowStatusOptions.Exited);
    }

    const popups = document.querySelectorAll('.chilipiper-popup');

    popups.forEach((popup) => popup.parentNode!.removeChild(popup));

    const parent = document.getElementById(ChiliPiperTargetID);

    if (parent) {
      while (parent.firstChild) {
        parent.removeChild(parent.lastChild!);
      }
    }
  };

  protected startTimeout = (): void => {
    // Ensure we don't start more than one timeout
    if (this.timeoutId) return;

    this.timeoutId = setTimeout(() => {
      if (this.eventHandler) {
        this.eventHandler('onTimeout', {});
      }
    }, 1000 * 60 * 10);

    this.updateStatus(HubspotChiliPiperFlowStatusOptions.Expired);
  };

  protected updateStatus = (status: string) => {
    if (status !== HubspotChiliPiperFlowStatusOptions.ExistingCustomer && this.isPayingCustomer) return;

    if (status !== HubspotChiliPiperFlowStatusOptions.Expired && this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    return fetch(API_CHILI_PIPER_UPDATE_STATUS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.user.email,
        status,
        messageId: this.timeoutMessageId, // Clear any previous pending messages
      }),
    }).then((res) => {
      res.json().then((body) => {
        this.timeoutMessageId = body.messageId;
      });
    });
  };

  public markExistingCustomer = () => {
    this.shouldSendExitEvent = false;
    this.isPayingCustomer = true;
    this.updateStatus(HubspotChiliPiperFlowStatusOptions.ExistingCustomer);
  };

  public requestFollowUp = () => {
    this.shouldSendExitEvent = false;
    this.updateStatus(HubspotChiliPiperFlowStatusOptions.RequestOtherTime);
  };
}
